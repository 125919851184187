.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 2px solid rgba(0,0,0,0.2);
    padding: 15px 0;
    border-left: none;
    border-right: none;
    font-weight: 700;
    align-items: center;
    .items {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-grow: 2;
        .active {
            line-height: 50px;
            text-align: center;
            width: 50px;
            height: 50px;
            transition: background-color 300ms, color 300ms;
            &:nth-child(n + 3) {
                display: none;
            }
            &:last-child {
                display: block;
            }
            @media(min-width: $breakpoint-mobile-min) {
                &:nth-child(n + 3) {
                    display: block;
                }
                &:nth-child(n + 5) {
                    display: none;
                }
                &:last-child {
                    display: block;
                }
            }
            @media(min-width: $breakpoint-tablet-min) {
                &:nth-child(n + 5) {
                    display: block;
                }
                &:nth-child(n + 8) {
                    display: none;
                }
                &:last-child {
                    display: block;
                }
            }
            @media(min-width: $breakpoint-desktop-min) {
                &:nth-child(n + 8) {
                    display: block;
                }
                &:nth-child(n + 10) {
                    display: none;
                }
                &:last-child {
                    display: block;
                }
            }
        }
        .inactive, .active:hover {
            background-color: $color-red;
            color: $color-font-second;
            line-height: 50px;
            text-align: center;
            width: 50px;
            height: 50px;
        }
    }
    p,a {
        line-height: normal;
        margin: 0;
        padding: 0;
    }
}
