.counters {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: url("/templates/main/images/media/background-counters.png");
        background-position: center;
        filter: blur(50px);
    }
    &__wrap {
        position: relative;
        z-index: 3;
        max-width: 1470px;
        margin: 0 auto;
        padding: 0 0 80px;
        @media(min-width: 1025px) {
            padding: 0 15px 130px;
        }
    }
    &__top {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-position: center bottom;
        background-repeat: no-repeat;
        padding: 40px 15px 25px;
        @media(min-width: 768px) {
            background-image: url("/templates/main/images/media/lines.svg");
            padding: 80px 0 100px;
        }
        @media(min-width: 1025px) {
            padding: 180px 0 100px;
        }
        &-left {
            text-align: right;
            align-items: flex-end;
        }
        &-right {
            text-align: right;
            @media(min-width: 576px) {
                text-align: left;
            }
        }
        &-left,
        &-right {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            padding: 0 15px;
            @media(min-width: 576px) {
                max-width: 250px;
            }
            @media(min-width: 768px) {
                max-width: 220px;
                padding: 0;
            }
            @media(min-width: 1025px) {
                max-width: 305px;
            }
        }
        &-circle {
            background-color: $color-white;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            border-radius: 50%;
            margin: 0 23px;
            box-shadow: 0 50px 90px rgba(0,0,0,0.16);
            width: 250px;
            height: 250px;
            display: none;
            @media(min-width: 768px) {
                display: flex;
            }
            @media(min-width: 1025px) {
                width: 300px;
                height: 300px;
            }
        }
        &-item {
            width: 100%;
            margin-bottom: 30px;
            &-value {
                font-weight: 700;
                letter-spacing: -0.03em;
                margin-bottom: 5px;
                font-size: 60px;
                line-height: 30px;
                @media(min-width: 1025px) {
                    font-size: 90px;
                    line-height: 55px;
                }
                &--text {
                    font-size: 27px;
                    @media(min-width: 1025px) {
                        font-size: 60px;
                    }
                }
            }
            &-title {
                padding-bottom: 5px;
                border-bottom: 2px solid $color-red;
                margin-bottom: 6px;
                font-size: 16px;
                @media(min-width: 1025px) {
                    font-size: 19px;
                    line-height: 23px;
                }
            }
            &-description {
                opacity: 0.5;
                font-size: 12px;
                @media(min-width: 1025px) {
                    font-size: 15px;
                }
            }
            &--small {
                @media(min-width: 1025px) {
                    max-width: 220px;
                }
                .counters__top-item {
                    &-value {
                        font-size: 60px;
                        line-height: 30px;
                        &--text {
                            font-size: 27px;
                        }
                    }
                }
            }
        }
    }
    &__bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        max-width: 768px;
        margin: 0 auto;
        justify-content: center;
        padding: 0 15px;
        @media(min-width: 768px) {
            justify-content: space-between;
        }
        @media(min-width: 1200px) {
            max-width: 100%;
            margin: 0;
        }
        &-item {
            background-color: $color-white;
            box-shadow: 0 25px 55px rgba(0,0,0,0.16);
            text-align: center;
            width: 100%;
            border-radius: 10px;
            max-width: 245px;
            margin: 0 10px 20px;
            padding: 16px 10px 22px;
            @media(min-width: 768px) {
                padding: 10px 10px 15px;
                margin: 0;
                max-width: calc(20% - 7px);
            }
            @media(min-width: 1200px) {
                padding: 16px 10px 22px;
            }
            &-title {
                font-size: 16px;
                line-height: 21px;
                font-weight: 700;
                color: $color-red;
                text-transform: uppercase;
            }
            &-value {
                font-size: 65px;
                font-weight: 700;
                line-height: 65px;
                margin-bottom: 5px;
            }
            &-description {
                font-size: 17px;
                line-height: 21px;
            }
        }
    }
}
