.opportunities {
    padding: 40px 15px 80px;
    @media(min-width: 576px) {
        padding: 40px 15px 130px;
    }
    &__wrap {
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: space-between;
        max-width: 800px;
        @media(min-width: 1366px) {
            max-width: 1345px;
        }
    }
    &__title {
        font-weight: 700;
        text-align: center;
        margin-bottom: 30px;
        margin: 0 auto 30px;
        font-size: 26px;
        line-height: 31px;
        max-width: 380px;
        @media(min-width: 576px) {
            font-size: 30px;
            line-height: 35px;
            max-width: 510px;
        }
        @media(min-width: 768px) {
            max-width: 670px;
            font-size: 40px;
            line-height: 45px;
        }
        @media(min-width: 1366px) {
            max-width: 595px;
            text-align: left;
            margin: 0;
        }
    }
    &__text {
        font-size: 16px;
        line-height: 24px;
        @media(min-width: 768px) {
            font-size: 18px;
            line-height: 26px;
        }
        @media(min-width: 1366px) {
            max-width: 720px;
        }
    }
}
