.group-slider {
    margin-bottom: 80px;
    position: relative;
    z-index: 3;
    @media(min-width: 768px) {
        padding: 0 15px;
    }
    &__wrap {
        max-width: 1500px;
        border-radius: 15px;
        background-color: $color-white;
        margin: 0 auto;
        @media(min-width: 768px) {
            margin: -55px auto 0;
        }
    }
    &__slider {
        border-radius: 15px;
        padding: 45px 0 0;
        @media(min-width: 768px) {
            padding: 45px 0 75px 0;
            background-color: $color-white;
            box-shadow: 0 50px 99px rgba(0,0,0,0.16);
            margin: 0 30px;
        }
        &-next, &-prev {
            width: 52px;
            height: 52px;
            position: absolute;

            margin: auto;
            background-color: $color-white;
            box-shadow: 0 15px 30px rgba(0,0,0,0.16);
            z-index: 3;
            cursor: pointer;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            top: 34px;
            bottom: auto;
            @media(min-width: 768px) {
                top: 0;
                bottom: 0;
            }
            &.swiper-button-disabled {
                display: none;
            }
            img {
                display: block;
                max-width: 100%;
                height: auto;
            }
        }
        &-prev {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            left: 0;
            right: auto;
        }
        &-next {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            right: 0;
            left: auto;
        }
    }
    &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        padding: 0 15px;
        @media(min-width: 768px) {
            padding: 0 65px;
        }
        @media(min-width: 1300px) {
            padding: 0 125px 0 75px;
        }
        &-left {
            width: 100%;
            text-align: center;
            margin-bottom: 30px;
            @media(min-width: 1025px) {
                padding-right: 96px;
                max-width: 310px;
            }
        }
        &-right {
            @media(min-width: 1025px) {
                max-width: calc(100% - 310px);
            }
        }
        &-title {
            font-size: 30px;
            line-height: 30px;
            font-weight: 700;
            margin-bottom: 14px;
            text-transform: uppercase;
        }
        &-btn {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            justify-content: center;
            text-align: center;
            background-color: $color-white;
            border: 3px solid $color-red;
            border-radius: 10px;
            width: 204px;
            min-height: 52px;
            font-size: 16px;
            font-weight: 700;
            box-shadow: 0 15px 30px rgba(0,0,0,0.16);
            margin: 0 auto;
            cursor: pointer;
        }
        &-text {
            font-size: 18px;
            line-height: 26px;
            padding-bottom: 42px;
            border-bottom: 2px solid rgba(0,0,0,0.1);
            margin-bottom: 25px;
        }
        &-fields {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
        }
        &-field {
            width: 100%;
            @media(min-width: 576px) {
                max-width: calc(100% / 3);
            }
            @media(min-width: 1300px) {
                margin-right: 125px;
                width: auto;
                max-width: none;
            }
            &:last-child {
                margin-right: 0;
            }
            &-value {
                font-weight: 700;
                font-size: 48px;
                @media(min-width: 1300px) {
                    font-size: 50px;
                }
            }
            &-text {
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
    &__menu {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        padding: 30px 15px;
        @media(min-width: 768px) {
            padding: 30px;
        }
        .swiper-wrapper {
            @media(min-width: 1366px) {
                flex-wrap: wrap;
                justify-content: space-between;
            }
            .swiper-slide {
                width: auto;
            }
        }
        &-item {
            padding: 15px 25px;
            font-size: 16px;
            line-height: 22px;
            text-transform: uppercase;
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            border-radius: 10px;
            font-weight: 600;
            cursor: pointer;
            &:before {
                content: "";
                width: 25px;
                height: 30px;
                background-position: center;
                background-repeat: no-repeat;
                margin-right: 10px;
            }
            &[data-id="128"] {
                &:before {
                    background-image: url("/templates/main/images/media/icons/star.svg");
                }
            }
            &[data-id="129"] {
                &:before {
                    background-image: url("/templates/main/images/media/icons/range.svg");
                }
            }
            &[data-id="130"] {
                &:before {
                    background-image: url("/templates/main/images/media/icons/woman.svg");
                }
            }
            &[data-id="131"] {
                &:before {
                    background-image: url("/templates/main/images/media/icons/man.svg");
                }
            }
            &[data-id="132"] {
                &:before {
                    background-image: url("/templates/main/images/media/icons/hobby.svg");
                }
            }
            &[data-id="133"] {
                &:before {
                    background-image: url("/templates/main/images/media/icons/zap-small.svg");
                }
            }
            &[data-id="134"] {
                &:before {
                    background-image: url("/templates/main/images/media/icons/b2b.svg");
                }
            }
            &[data-id="135"] {
                &:before {
                    background-image: url("/templates/main/images/media/icons/b2c.svg");
                }
            }
            &.active {
                background-color: $color-white;
                box-shadow: 0 15px 30px rgba(0,0,0,0.16);
                &[data-id="128"] {
                    &:before {
                        background-image: url("/templates/main/images/media/icons/star-red.svg");
                    }
                }
                &[data-id="129"] {
                    &:before {
                        background-image: url("/templates/main/images/media/icons/range-red.svg");
                    }
                }
                &[data-id="130"] {
                    &:before {
                        background-image: url("/templates/main/images/media/icons/woman-red.svg");
                    }
                }
                &[data-id="131"] {
                    &:before {
                        background-image: url("/templates/main/images/media/icons/man-red.svg");
                    }
                }
                &[data-id="132"] {
                    &:before {
                        background-image: url("/templates/main/images/media/icons/hobby-red.svg");
                    }
                }
                &[data-id="133"] {
                    &:before {
                        background-image: url("/templates/main/images/media/icons/zap-small-red.svg");
                    }
                }
                &[data-id="134"] {
                    &:before {
                        background-image: url("/templates/main/images/media/icons/b2b-red.svg");
                    }
                }
                &[data-id="135"] {
                    &:before {
                        background-image: url("/templates/main/images/media/icons/b2c-red.svg");
                    }
                }
            }
        }
    }
    &__connected {
        &-items {
            opacity: 0;
            pointer-events: none;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            padding: 15px;
            background-color: rgba(26,27,28,0.7);
            backdrop-filter: blur(5px);
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            align-items: center;
            z-index: 9;
            &.open {
                opacity: 1;
                pointer-events: all;
            }
        }
        &-wrap {
            background-color: $color-white;
            border-radius: 15px;
            max-width: 970px;
            padding: 45px 15px 90px;
            width: 100%;
            position: relative;
        }
        &-bottom {
            max-width: 731px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
        }
        &-title {
            width: 100%;
            text-transform: uppercase;
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 45px;
            text-align: center;
        }
        &-item {
            width: 100%;
            min-height: 120px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            border: 1px solid #E4E4E4;
            border-left: none;
            max-width: 50%;
            @media(min-width: 576px) {
                max-width: calc(100% / 3);
            }
            &:first-child {
                border-left: 1px solid #E4E4E4;
                @media(min-width: 576px) {
                    border-top-left-radius: 10px;
                }
            }
            &:nth-child(3) {
                @media(min-width: 576px) {
                    border-top-right-radius: 10px;
                }
            }
            &:nth-child(n + 4) {
                @media(min-width: 576px) {
                    border-top: none;
                }
            }
            &:nth-child(n + 3) {
                @media(max-width: 575px) {
                    border-top: none;
                }
            }
            &:nth-child(odd) {
                @media(max-width: 575px) {
                    border-left: 1px solid #E4E4E4;
                }
            }
            &:nth-child(4) {
                @media(min-width: 576px) {
                    border-bottom-left-radius: 10px;
                    border-left: 1px solid #E4E4E4;
                }
            }
            img {
                max-width: 80%;
                @media(min-width: 576px) {
                    max-width: 70%;
                }
            }
        }
        &-close {
            position: absolute;
            top: 13px;
            right: 16px;
            width: 15px;
            height: 15px;
            cursor: pointer;
            &:after, &:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                width: 100%;
                height: 2px;
                border-radius: 1px;
                background-color: rgba(0,0,0,0.21);
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}
[data-user-browser="Chrome"],
[data-user-browser="Edge"],
[data-user-browser="Opera"] {
    .group-slider__wrap {
        background-color: rgba(255,255,255,0.2);
        backdrop-filter: blur(50px);
    }
}
