.avt-section-main {
    // padding-top: 80px;
    // padding-bottom: 140px;
    // min-height: 80vh;
    padding-top: 67px;
    background: $color-blue-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: 100vh;
    max-height: 1080px;
    perspective: 2738px;
    perspective-origin: 100% 75%;
    @media(min-width: $breakpoint-desktop-large-min) {
    }
    h1 {
        color: $color-font-second;
        margin: 0 auto 20px;
        text-align: center;
        @media(min-width: $breakpoint-mobile-min) {
            text-align: left;
        }
    }
    .avt-lead-text {
        color: $color-font-second;
        text-align: left;
    }
    .avt-buttons {
        padding-left: 15px;
    }
    .avt-btn-sm {
        margin: 0 auto 15px;
        box-shadow: $boxshadow-btn;
        @media(min-width: $breakpoint-mobile-min) and (max-width: $breakpoint-mobile-max) {
            float: left;
        }
    }
    .avt-main-info {
        position: absolute;
        top: 125px;
        left: 0;
        right: 0;
        max-width: 610px;
        @media(min-width: $breakpoint-mobile-min) {
            right: auto;
            left: 40px;
        }
        @media(min-width: $breakpoint-tablet-min) {
            left: 16px;
        }
        @media(min-width: $breakpoint-desktop-large-min){
            left: 50px;
        }
        @media(min-width: 1800px) {
            left: 100px;
        }
        @media(min-width: 1920px) {
            left: 256px;
        }
        .avt-lead-text{
            font-size: 17px;
            line-height: 30px;
            font-weight: 400;
            opacity: 0.5;
        }
    }
}

.avt-section-data {
    background-color: $bg-additional;
}

.avt-section-statistics {
    background-color: $bg-second;
    padding-top: 8px;
    padding-bottom: 32px;
    position: relative;
    @media(min-width: $breakpoint-desktop-large-min) {
        min-height: 640px;
    }
}

.avt-section-portfolio {
    text-align: center;
    // padding-bottom: 64px;
    background-color: $bg-second;
    .avt-portfolio-header {
        padding-top: 64px;
        padding-bottom: 64px;
        @media(min-width: $breakpoint-tablet-min) {
            padding-bottom: 16px;
        }
    }
    .avt-header-line {
        @media(min-width: $breakpoint-tablet-min) {
            // margin-bottom: 24px;
        }
    }
    .avt-categories {}
    .avt-portfolio-description {
        .description{
            padding-top: 48px;
            padding-bottom: 48px;
            text-align: left;
        }
    }
    .avt-button-main {
        margin: 0 auto;
    }
}

.avt-section-catalog {
    padding-top: 64px;
    padding-bottom: 64px;
    background-color: $bg-main;
    .avt-button-main {
        margin: 16px auto 0;
    }
}

.avt-section-kiosk {
    position: relative;
    padding-top: 64px;
    padding-bottom: 64px;

    .avt-kiosk-header {
        text-align: center;
        color: $color-font-second;
        font-size: 25px;
        font-weight: 700;
        span{
            margin-top: 32px;
            color: $color-font-second;
            display: block;
            font-size: 16px;
            line-height: 33px;
            font-weight: 300;
        }
    }
}


.avt-section-fotopolis {
    // padding-top: 32px;
    // padding-bottom: 72px;
    // min-height: 600px;
    background-position: center;
    background-size: cover;
    @media(min-width: $breakpoint-tablet-min){
        padding-bottom: 0;
    }

}
.avt-section-news {
    &#avt-index-news{
        .avt-news-header {
            margin-bottom: 56px;
        }
        .avt-news-news {
            .avt-news-header {
                @media(min-width: $breakpoint-desktop-large-min){
                    padding-bottom: 41px;
                }
            }
        }
    }
    .avt-job-wrap {
        max-width: 530px;
        margin: 0 auto;
    }
    .avt-news-header {
        color: $color-font-additional;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        padding: 30% 0;
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        @media(min-width: $breakpoint-tablet-min) {
            padding: 56px 0;
        }

    }
    .avt-news-job {
        background-image: url("../images/job.png");
        background-color: $color-black-dark;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 80px 15px 120px;
        .avt-horizontal-line {
            width: 100%;
            height: 2px;
            background: rgba(255,255,255,0.2);
            margin-bottom: 56px;
            @media(min-width: $breakpoint-desktop-large-min) {
                margin-bottom: 0;
            }
        }
        .avt-btn-border {
            margin: 32px auto 0;
        }
    }
    .avt-news-news {
        background-image: url("../images/news.png");
        background-color: $bg-second;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media(min-width: $breakpoint-tablet-min){
            padding: 80px 15px 120px;
        }
        .avt-horizontal-line {
            width: 100%;
            height: 2px;
            background: rgba(0,0,0,0.2);
            margin-bottom: 56px;
            @media(min-width: $breakpoint-desktop-large-min) {
                margin: 16px 0;
            }
        }
        .avt-btn-border {
            margin: 32px auto 0;
        }
    }
}
