nav.top-nav {
    @media(max-width: $breakpoint-tablet-max) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    a, span {
        &, &:link, &:visited, &:hover, &:active, &:focus {
            color: $color-font-second;
            text-decoration: none;
            position: relative;
        }
        &:hover {
            &:after {
                width: 100%;
            }
        }
        &.active {
            color: $color-font-additional;
        }
        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 0;
            height: 3px;
            background-color: $color-red;
            -webkit-transition: width .25s ease-out 0s;
            transition: width .25s ease-out 0s;
        }
    }

    li.active {
        a {
            &:after{
                width: 100%;
            }
        }
    }
    span {
        position: relative;
            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: -1px;
                width: 0;
                height: 3px;
                background-color: $color-red;
                -webkit-transition: width .25s ease-out 0s;
                transition: width .25s ease-out 0s;
            }
            &:hover {
                &:after{
                    width: 100%;
                }
            }
    }
    .avt-nav-list {
        @media(min-width: $breakpoint-desktop-min) {
            font-size: 0;
            height: $height-header;
        }
        li {
            font-weight: 600;
            width: 100%;
            @media(max-width: $breakpoint-tablet-max) {
                font-size: 16px;
                line-height: 1;

                +li {
                    margin: 20px 0 0;
                }
            }
            @media(min-width: $breakpoint-desktop-min) {
                width: auto;
                display: inline-block;
                font-size: 15px;
                line-height: $height-header;
                padding-right: 20px;
                cursor: pointer;
            }

            span,
            a {
                color: #1A1B1C;
                @media(min-width: $breakpoint-desktop-min) {
                    color: #fff;
                }
            }
        }
    }
    .avt-nav-menu {
        font-size: 12px;
        text-align: right;
        height: $height-header;
        line-height: $height-header;
        color: $color-font-second;
        @media(min-width: $breakpoint-desktop-min) {
            color: $color-font-main;
        }
        svg {
            fill: $color-font-second;
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 3px;
        }
    }
    .avt-nav-shortcuts {
        color: $color-font-second;
        font-weight: 400;
        @media(max-width: $breakpoint-tablet-max) {
            margin: 55px 0 0;
            font-size: 13px;
            color: #000;
        }
        @media(min-width: $breakpoint-desktop-min) {
            font-size: 12px;
            text-align: right;
            height: $height-header;
            line-height: $height-header;
        }
        ul {
            font-weight: 600;
            @media(max-width: $breakpoint-tablet-max) {
                margin: 5px 0 0;
            }
            @media(min-width: $breakpoint-desktop-min) {
                padding-left: 10px;
                display: inline-block;
            }
        }
        li {
            width: 100%;
            @media(max-width: $breakpoint-tablet-max) {
                font-size: 15px;
                line-height: 2;
            }
            @media(min-width: $breakpoint-desktop-min) {
                display: inline-block;
                width: auto;
                &:after{
                    content: "|";
                    display: inline-block;
                    padding: 0 5px;
                    color: $color-font-second;
                }
                &:last-child:after {
                    display: none;
                }
            }

            a {
                color: #1A1B1C;
                @media(min-width: $breakpoint-desktop-min) {
                    color: #fff;
                }
            }
        }
    }
}
