body.overflow {
    overflow: hidden;
}
.media-contact {
    padding: 45px 0 90px;
    // background-color: $color-white;
    @media(min-width: 768px) {
        background-color: transparent;
        padding: 0 15px 110px;
    }
    &__title {
        width: 100%;
        text-align: center;
        font-weight: 700;
        margin-bottom: 65px;
        font-size: 25px;
        padding: 0 15px;

        @media(min-width: 576px) {
            font-size: 30px;
        }
        @media(min-width: 768px) {
            padding: 0;
            font-size: 40px;
        }
    }
    &__subtitle {
        width: 100%;
        text-align: center;
        letter-spacing: 0.013em;
        margin-bottom: 9px;
        padding: 0 15px;

        @media(min-width: 768px) {
            padding: 0;
        }
        p {
            margin: 0;
            padding: 0;
            display: block;
            font-size: 19px;
            line-height: 23px;
            @media(min-width: 576px) {
                display: inline;
            }
            @media(min-width: 768px) {
                font-size: 23px;
                line-height: 26px;
            }
        }
    }
    &__items {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        @media(min-width: 576px) {
            justify-content: center;
        }
        @media(min-width: 768px) {
            max-width: 390px;
            display: block;
        }
    }
    &__item {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        padding: 0 15px;
        margin-bottom: 50px;
        @media(min-width: 768px) {
            margin-bottom: 100px;
            padding: 0;
        }
        &-image {
            border-radius: 50%;
            overflow: hidden;
            box-shadow: 0 25px 50px rgba(0,0,0,0.53);
            width: 75px;
            height: 75px;
            @media(min-width: 768px) {
                width: 115px;
                height: 115px;
            }
            img {
                max-width: 100%;
                height: auto;
                display: block;
            }
        }
        &-info {
            padding-left: 14px;
            @media(min-width: 768px) {
                padding-left: 28px;
            }
        }
        &-name {
            font-weight: 700;
            margin-bottom: 6px;
            font-size: 16px;
            @media(min-width: 768px) {
                font-size: 22px;
            }
        }
        &-role {
            font-weight: 500;
            color: $color-red;
            text-transform: uppercase;
            margin-bottom: 7px;
            font-size: 10px;
            @media(min-width: 768px) {
                font-size: 14px;
            }
        }
        &-mail {
            font-weight: 400;
            margin-bottom: 5px;
            font-size: 12px;
            @media(min-width: 768px) {
                font-size: 17px;
            }
        }
        &-tel {
            font-weight: 400;
            text-decoration: none;
            font-size: 12px;
            @media(min-width: 768px) {
                font-size: 17px;
            }
        }
    }
    &__form {
        input,
        textarea {
            font-family: 'Switzer', sans-serif;
        }
        &-wrap {
            background-color: $color-white;
            width: 100%;
            border-radius: 15px;
            box-shadow: 0 50px 99px rgba(0,0,0,0.16);
            padding: 20px 10px 35px;
            margin: 0 10px;
            @media(min-width: 576px) {
                padding: 30px 15px 45px;
            }
            @media(min-width: 768px) {
                max-width: calc(100% - 390px);
                padding: 30px 30px 45px;
                margin: 0;
            }
            @media(min-width: 1025px) {
                padding: 35px 45px 50px;
            }
        }
    }
    &__input {
        border: none;
        border-bottom: 1px solid #DFDFDF;
        width: 100%;
        padding: 0;
        padding-bottom: 6px;
        font-size: 15px;
        font-weight: 700;
        &:focus,
        &:active,
        &.filled {
            outline: none;
            border: none;
            border-bottom: 1px solid #DFDFDF;
            & ~ .media-contact__input-placeholder {
                transform: translateY(-23px);
                font-size: 10px;
            }
        }
        &-wrap {
            position: relative;
            margin-bottom: 25px;
        }
        &-placeholder {
            position: absolute;
            bottom: 7px;
            font-size: 15px;
            transition: transform .2s ease-in-out, font-size .2s ease-in-out;
            pointer-events: none;
            user-select: none;
        }
    }
    &__textarea {
        resize: none;
        width: 100%;
        min-height: 125px;
        border: 1px solid #DFDFDF;
        border-radius: 5px;
        padding: 12px 14px;
        font-size: 14px;
        margin-bottom: 30px;
        &:focus,
        &:active {
            outline: none;
        }
    }
    &__submit {
        margin-top: 30px;
        width: 100%;
        min-height: 80px;
        background-color: $color-red;
        color: $color-white;
        outline: none;
        border: none;
        border-radius: 4px;
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
        &.disabled {
            opacity: 0.4;
        }
    }
    &__float-btn {
        position: fixed;
        z-index: 9;
        bottom: 10px;
        right: 10px;
        display: flex;
        flex-wrap: wrap;
        background-color: $color-red;
        color: $color-white;
        font-size: 16px;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
        font-weight: 700;
        cursor: pointer;

        width: 50px;
        height: 50px;
        box-shadow: 0 15px 25px rgba(0,0,0,0.3);
        border-radius: 50%;
        @media(min-width: 992px) {
            border-radius: 4px;
            width: 203px;
            height: 68px;
            box-shadow: none;
        }
        img {
            display: block;
            max-width: 100%;
            height: auto;
            @media(min-width: 992px) {
                margin-right: 8px;
            }
        }
    }
    &__wrap {
        max-width: 990px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        &-main {
            position: fixed;
            pointer-events: none;
            opacity: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(26,27,28,0.7);
            backdrop-filter: blur(5px);
            z-index: 9;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            transition: opacity .2s ease-in-out;
            padding: 15px;
            overflow: auto;
            @media(min-width: 1025px) {
                padding: 0;
                align-items: center;
                align-content: center;
                overflow: visible;
            }
            &.open {
                opacity: 1;
                pointer-events: all;
            }
            .media-contact {
                &__wrap {
                    position: relative;
                    max-width: 500px;
                    @media(min-width: 1025px) {
                        max-width: 990px;
                    }
                }
                &__items {
                    background-image: url("/templates/main/images/media/media-bg.png");
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 100%;
                    max-width: 100%;
                    padding: 30px 15px;
                    justify-content: center;
                    display: flex;
                    flex-wrap: wrap;
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                    @media(min-width: 1025px) {
                        padding: 35px 30px 35px 60px;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        border-top-left-radius: 15px;
                        border-bottom-left-radius: 15px;
                        max-width: 50%;
                        display: block;
                    }
                    &-title {
                        font-size: 16px;
                        font-weight: 700;
                        text-transform: uppercase;
                        margin-bottom: 60px;
                        text-align: center;
                        width: 100%;
                    }
                    .media-contact__items {
                        margin: 0;
                        padding: 0;
                        background-image: none;
                        width: 100%;
                        max-width: none;
                        .media-contact__item {
                            width: 100%;
                            &-info {
                                max-width: calc(100% - 75px);
                                @media(min-width: 768px) {
                                    max-width: calc(100% - 115px);
                                }
                            }
                        }
                    }
                }
                &__item {
                    margin-bottom: 30px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &__form {
                    &-title {
                        font-size: 16px;
                        font-weight: 700;
                        text-transform: uppercase;
                        margin-bottom: 60px;
                        text-align: center;
                    }
                    &-wrap {
                        max-width: 50%;
                        width: 100%;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        @media(max-width: 1024px) {
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 15px;
                            border-bottom-left-radius: 15px;
                            margin: 0;
                            max-width: 100%;
                        }
                    }
                }
                &__address {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    margin-top: 30px;
                    @media(min-width: 1025px) {
                        margin-top: 80px;
                    }
                    &-text {
                        padding-left: 18px;
                        p {
                            font-size: 13px;
                            line-height: 22px;
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
                &__close {
                    position: absolute;
                    top: 13px;
                    right: 16px;
                    width: 15px;
                    height: 15px;
                    cursor: pointer;
                    &:after, &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        margin: auto;
                        width: 100%;
                        height: 2px;
                        border-radius: 1px;
                        background-color: rgba(0,0,0,0.21);
                        transform: rotate(45deg);
                    }
                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    &__message {
        display: none;
        margin-top: 10px;
        font-size: 13px;
        text-align: center;
        &.error {
            display: block;
            color: #d32e2f;
        }
        &.success {
            display: block;
        }
    }
}
