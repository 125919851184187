.partners {
    text-align: center;
    padding: 0 15px;
    margin-bottom: 90px;
    @media(min-width: 1025px) {
        margin-bottom: 145px;
    }
    &__title {
        font-weight: 700;
        font-size: 26px;
        margin-bottom: 45px;
        @media(min-width: 576px) {
            font-size: 40px;
        }
    }
    &__logo {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-bottom: 40px;
        max-width: 50%;
        @media(min-width: 576px) {
            max-width: calc(100% / 3);
        }
        @media(min-width: 768px) {
            max-width: 25%;
        }
        @media(min-width: 1200px) {
            max-width: 20%;
        }
        @media(min-width: 1440px) {
            max-width: calc(100% / 6);
        }
        img {
            max-width: 90%;
        }
    }
    &__wrap {
        max-width: 1440px;
        margin: 0 auto;
        background-color: $color-white;
        border-radius: 15px;
        box-shadow: 0 50px 99px rgba(0,0,0,0.16);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 40px 15px 5px;
        @media(min-width: 576px) {
            padding: 40px 30px 5px;
        }
        @media(min-width: 1025px) {
            padding: 40px 70px 5px;
        }
    }
}
