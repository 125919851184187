.swiper-container{
    .swiper-slide{
        img{
            margin: 0 auto;
            display: block;
        }
        .swiper-slide-shadow-left, .swiper-slide-shadow-right {
            display: none;
        }
    }
}
