#avt-news-slider {
    width: 100%;
    .avt-slider-header {
        color: $color-font-additional;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        margin-top: 50px;
        @media(min-width: $breakpoint-mobile-min) {
            font-size: 20px;
        }
    }
    .avt-slide {
        max-width: 1440px;
        margin: 0 auto;
        padding: 50px 15px;
        @media(min-width: $breakpoint-desktop-min) {
            display: flex;
            padding: 50px 60px;
        }
        .avt-slide-image {
            @media(min-width: $breakpoint-desktop-min) {
                min-width: 300px;
            }
        }
    }
    .swiper-slide {

        img {
            max-width: 475px;
            width: 100%;
        }
    }
    .avt-info {
        @media(min-width: $breakpoint-desktop-large-min){
            padding-right: 50px;
        }
        .avt-title {
            font-size: 18px;
            font-weight: 400;
            text-align: center;
            line-height: 30px;
            margin: 24px 0;
            @media(min-width: $breakpoint-mobile-min) {
                font-size: 30px;
                line-height: 50px;
            }
            @media(min-width: $breakpoint-desktop-min) {
                text-align: left;
            }
        }
        .avt-description {
            margin-bottom: 24px;
        }
        .avt-read-more {
            text-decoration: none;
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 700;
            text-align: right;
            display: block;
            color: $color-font-main;
            @media(min-width: $breakpoint-desktop-min) {
                text-align: left;
            }
            svg {
                width: 14px;
                display: inline-block;
                margin-bottom: 1.5px;
                margin-left: 8px;
                fill: $color-red;
            }
        }
    }
    .swiper-button-prev , .swiper-button-next {
        background: none;
        top: 105px;
        width: 38px;
        @media(min-width: $breakpoint-mobile-min) {
            top: 45%;
        }
        @media(min-width: $breakpoint-desktop-min) {
            top: calc(50% - 22px);
        }
        img {
            width: 100%;
        }
    }
    .swiper-pagination {
        left: 0;
        right:0;
        top: 90px;
        @media(min-width: 1440px) {
            top: auto;
            bottom: 50px;
        }
        .swiper-pagination-bullet {
            background-color: rgba(0,0,0,0.3);
            margin: 0 7.5px;
            width: 10px;
            height: 10px;
        }
        .swiper-pagination-bullet-active {
            background-color: $color-black-0;
        }
    }

}
