.avt-reklama-pages {
    aside {
        display: none;
        height: 100%;
        padding: 30px 0;
        margin: 0;
        background: #F1F1F1;
        @media(min-width: $breakpoint-desktop-min) {
            display: block;
        }
    }
    
    main {
        padding: 30px 0;
    }
    
    .page-bg {
        position: relative;
        
        &:before {
            @media(min-width: $breakpoint-desktop-min) {
                display: block;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                min-width: 20px;
                width: calc((100% - 1170px) / 2);
                background: #F1F1F1;
            }
        }
    }
    .moduletable {
        height: 100%;
    }
}