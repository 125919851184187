.avt-header-line {
    text-transform: uppercase;
    height: 70px;
    font-size: 0px;
    vertical-align: top;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .avt-header{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        &:after, &:before{
            content: "";
            position: absolute;
            display: block;
            width: 37%;
            height: 25%;
            border-top: 1px solid rgba(0,0,0,0.2);
            bottom:25%;
        }
        &:before{
            left:0;
            border-left: 1px solid rgba(0,0,0,0.2);
            @media(min-width: $breakpoint-desktop-large-min){
                left: 2.5%;
            }
        }
        &:after{
            right:0;
            border-right: 1px solid rgba(0,0,0,0.2);
            @media(min-width: $breakpoint-desktop-large-min){
                right: 2.5%;
            }
        }
    }
    strong {
        color: $color-font-additional;
        font-size: 60px;
        font-weight: 700;
    }
    span {
        margin-left: 24px;
        display: inline-block;
        font-weight: 700;
        font-size: 18px;
    }
}
