.avt-news-list {
    .avt-news {
        a {
            text-decoration: none;
        }
        border-bottom: 2px solid rgba(0,0,0,0.2);
        padding-bottom: 24px;
        margin-bottom: 24px;
        @media(min-width: $breakpoint-desktop-min) {
            padding-bottom: 34px;
            margin-bottom: 34px;
        }
        &:last-child{
            border-bottom: none;
            padding-bottom: 0;
        }
    }
    
    .col-first {
        @media(max-width: $breakpoint-mobile-max) {
            flex: none;
            display: block;
            margin: 0 auto;
        }
        @media(min-width: $breakpoint-tablet-min) {
            flex: 0 0 330px;
        }
        max-width: 330px;
    }
    
    .col-second {
        @media(max-width: $breakpoint-mobile-max) {
            flex: none;
        }
        @media(min-width: $breakpoint-desktop-min) {
            padding-left: 90px;
        }
    }
    
    .img-w {
        display: block;
        border: 1px solid #d5d5d5;
        padding: 9px;
        height: 300px;
        margin: 0 0 30px;
        font-size: 0;
        @media(min-width: $breakpoint-tablet-min) {
            margin: 0;
        }
        
        &:before {
            display: inline-block;
            content: '';
            font-size: 0;
            height: 100%;
            vertical-align: middle;
        }
        
        img {
            max-height: 100%;
            width: auto;
        }
    }
}