.avt-section-contact {
    background-color: $bg-second;
    .avt-name {
        font-weight: 600;
        font-size: 22px;
        margin: 32px 0;
        @media(min-width: $breakpoint-mobile-min) {
            font-size: 25px;
        }
        @media(min-width: $breakpoint-desktop-min){
            margin-top: 0;
        }
    }
    .avt-address-link {
        display: block;
        margin-bottom: 24px;
    }
    .avt-info {
        margin-bottom: 24px;
    }
    .avt-info, .avt-bank-account, .avt-address-link {
        color: $color-font-main;
        text-decoration: none;
        font-weight: 400;
        font-size: 17px;
        line-height: 30px;
    }
    .google-maps {
        @media(min-width: $breakpoint-tablet-min){
            padding-bottom: 50%;
        }
        @media(min-width: $breakpoint-desktop-min){
            padding-bottom: 120%;
        }
        @media(min-width: $breakpoint-desktop-large-min){
            padding-bottom: 600px;
        }
    }
}
