.avt-article-nav {
    background-color: $bg-second;
    text-align: center;
    .avt-button-main {
        margin: 0 auto;
    }
    .avt-article-btn-prev, .avt-article-btn-next {
        margin-top: 24px;
        display: inline-block;
        max-width: 300px;
        text-align: center;
        text-decoration: none;
        @media(min-width: $breakpoint-tablet-min) {
            margin-top: 0;
        }
        span{
            display: inline-block;
            font-weight: 700;
            color: $color-font-additional;
        }
        svg{
            display: inline-block;
            width: 22px;
            height: 10px;
            fill: $color-red;
        }
    }
    .avt-article-btn-next {
        svg{
            @media(min-width: $breakpoint-tablet-min) {
                margin-left: 10px;
            }
        }
    }
    .avt-article-btn-prev {
        svg{
            transform: rotate(180deg);
            @media(min-width: $breakpoint-tablet-min) {
                margin-right: 10px;
            }
        }
    }
    .avt-article-title {
        display: none;
        font-size: 25px;
        font-weight: 500;
        text-align: center;
        margin-top: 16px;
        @media(min-width: $breakpoint-desktop-min) {
            display: block;
        }
    }
}
