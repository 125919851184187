.avt-job-offer{
    text-align: left;
    margin-bottom: 56px;
    @media(min-width: $breakpoint-desktop-large-min){
        min-height: 141px;
        align-items: center;
    }
    @media(min-width: $breakpoint-desktop-large-min) {
        margin-bottom: 0;
    }
    .avt-job-link{
        text-decoration: none;
        .avt-position, .avt-department{
            font-weight: 400;
            &, span{
                color: $color-font-second;
                font-size: 22px;
            }
            span{
                font-weight: 700;
            }
        }
    }
}

#avt-about-jobs {
    @media(min-width: $breakpoint-tablet-min) {
        padding: 0;
    }
    @media(min-width: $breakpoint-desktop-min) {
        background-image: none;
    }
    .avt-horizontal-line {
        margin-bottom: 16px;
    }
    .avt-news-header {
        font-size: 20px;
    }
    .avt-job-offer {
        margin-bottom: 16px;
    }
    .avt-job-link {
        display: block;
        width: 100%;
        ul {
            width: 100%;
            display: block;
        }
        .avt-position, .avt-department {
            font-weight: 400;
            color: rgba(255,255,255,0.7);
            &, span {
                font-size: 14px;
                line-height: 30px;
                @media(min-width: $breakpoint-mobile-min) {
                    font-size: 22px;
                }
            }
            span {
                color: $color-font-second;
                font-weight: 600;
            }
        }
    }
    .avt-read-more {
        margin-top: 24px;
        text-decoration: none;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 700;
        text-align: right;
        display: block;
        width: 100%;
        color: $color-font-second;
        @media(min-width: $breakpoint-mobile-min) {
            text-align: left;
            margin-bottom: 24px;
        }
        svg {
            width: 14px;
            display: inline-block;
            margin-bottom: 1.5px;
            margin-left: 8px;
            fill: $color-font-second;
        }
    }
}
#avt-jobs-bg {
    background-color: $bg-additional;
    background-image: url("../images/job.png");
    background-size: cover;
}
.avt-job-offer-article {
    text-align: center;
    .avt-job-type {
        margin-top: 24px;
        color: $color-font-additional;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 26px;
        }
    }
    .avt-job-position {
        margin-top: 24px;
        font-size: 24px;
        font-weight: 400;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 45px;
        }
    }
    .avt-job-lead-text {
        margin-top: 40px;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        margin-bottom: 40px;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 22px;
            line-height: 35px;
        }
    }
    .avt-job-list {
        margin-top: 24px;
        text-align: left;
        font-size: 16px;
        line-height: 30px;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 21px;
        }
    }
    .avt-job-text {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        line-height: 30px;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 21px;
        }
        
        ul {
            li {
                position: relative;
                padding: 0 0 0 18px;
                
                &:before {
                    display: block;
                    content: '';
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    background: $color-font-additional;
                    position: absolute;
                    left: 0;
                    top: 12px;
                }
                @media(min-width: $breakpoint-tablet-min) {
                    padding: 0 0 0 24px;
                    
                    &:before {
                        width: 9px;
                        height: 9px;
                    }
                }
                
                + li {
                    margin: 4px 0 0;
                }
            }
        }
        
        .imagesInContent {
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
    .avt-job-header {
        margin-top: 24px;
        text-transform: uppercase;
        font-weight: 700;
        text-align: left;
        font-size: 16px;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 21px;
        }

    }
}
