.avt-section-publicity {
    background-color: $bg-main;
    padding-top: 64px;
    padding-bottom: 64px;
    @media(min-width: $breakpoint-tablet-min) {
        padding-top: 0;
    }
    .avt-container-1400 {
        position: relative;
    }
    .avt-publicity-description {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        align-content: center;
        &__left {
            margin-bottom: 45px;
            @media(min-width: 1025px) {
                margin-bottom: 0;
            }
        }
        &__right {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            margin-bottom: 30px;

        }
        .type {
            color: $color-font-additional;
            font-size: 19px;
            font-weight: 700;
            margin-bottom: 32px;
            text-align: left;
        }
        .title {
            text-align: left;
            @media(min-width: $breakpoint-desktop-min){
                margin-bottom: 32px;
            }
            strong {
                color: $color-font-additional;
            }
        }
        .description {
            text-align: left;
        }
    }
    .avt-publicity-image {
        padding-left: 10%;
        @media(min-width: 576px) {
            padding-left: 150px;
        }
        @media(min-width: 992px) and (max-width: 1366px) {
            padding-left: 0;
        }
        img {
            width: 100%;
            max-width: 700px;
            display: block;
            margin: 0 auto;
        }
    }
    .avt-button-main {
        margin: 0 auto;
    }

    &.reklama-page {
        padding-top: 64px;
        @media(min-width: $breakpoint-desktop-large-min) {
            padding-top: 150px;
            padding-bottom: 170px;
        }

        .avt-publicity-description {
            max-width: 1000px;
            margin: 0 auto;

            .title {
                text-align: center;

                strong {
                    color: #000;
                }
            }
            .description {
                text-align: center;
            }
        }
    }
}
