.avt-job-offers {
    .avt-job-offer {
        .avt-department {
            text-align: center;
            @media(min-width: $breakpoint-tablet-min) {
                text-align: left;

            }
            color: $color-font-additional;
            font-weight: bold;
        }
        .avt-title {
            font-size: 24px;
            margin-top: 16px;
            @media(min-width: $breakpoint-tablet-min) {
                font-size: 30px;
            }
            font-weight: 600;
            
            a {
                color: $color-font-main;
                &:hover {
                    color: #d32e2f;
                }
            }
        }
        .avt-button-read {
            text-align: center;
            @media(min-width: $breakpoint-tablet-min) {
                text-align: left;
            }
        }
    }
    .avt-horizontal-line {
        width: 100%;
        height: 1px;
        background-color: rgba(0,0,0,0.2);
    }
}
