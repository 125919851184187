.avt-reklama-grid {
    .row-item {
        min-height: 156px;
        margin-top: 30px;
        &:nth-child(1) {
            margin-top: 0;
        }
        @media(min-width: $breakpoint-mobile-min) {
            &:nth-child(2) {
                margin-top: 0;
            } 
        }
        @media(min-width: $breakpoint-tablet-min) {
            &:nth-child(3) {
                margin-top: 0;
            } 
        }
    }
    
    .grid-item {
        width: 100%;
        height: 100%;
    }
    
    .d-table-cell {
        position: relative;
        vertical-align: middle;
        text-align: center;
        color: #d32e2f;
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
    }
    
    a.d-table-cell {
        &:hover {
            .material {
                transform: translateY(-20px);
            }

            .deco-1 {
                opacity: 1;
                transform: translateY(-15px);
            }
            .deco-2 {
                opacity: 0.8;
                transform: translateY(-10px);
            }
            .deco-3 {
                opacity: 0.6;
                transform: translateY(-5px);
            }
        }
    }
    
    .material {
        align-items: center;
        border: 1px solid #e4e4e4;
        padding: 10px;
        height: 100%;
        position: relative;
        z-index: 5;
        background: #fff;
        outline: 1px solid transparent;
        transition: transform 0.25s ease 0s;

        .in {
            width: 100%;
        }
    }

    .deco {
        position: absolute;
        top: 0;
        height: 100%;
        opacity: 0;
        background-color: #dfdfdf;
        transition: all 0.25s ease 0s;

        &.deco-1 {
            z-index: 3;
            left: 5px;
            right: 5px;
        }
        &.deco-2 {
            z-index: 2;
            left: 10px;
            right: 10px;
        }
        &.deco-3 {
            z-index: 1;
            left: 15px;
            right: 15px;
        }
    }
}