.avt-bs-hover {
    @media(min-width: $breakpoint-desktop-large-min){
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        &:hover {
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22) !important;
        }
    }
}

.avt-bs-hover-lg{
    @extend .avt-bs-hover;
    @media(min-width: $breakpoint-desktop-large-min){
        &:hover {
            box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        }
    }
}
