.grid-icons {
    text-align: center;
    @media(min-width: $breakpoint-tablet-min) {
        padding-top: 80px;
        padding-bottom: 48px;
    }
    &__item {
        margin-top: 32px;
        @media(min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-desktop-max) {
            // border: 0.5px solid rgba(0,0,0,0.2);
            margin-top: 0;
            padding: 32px 15px;
            &:first-child {
                border-top: none;
                border-left: none;
            }
            &:nth-child(2) {
                border-top: none;
            }
            &:nth-child(3) {
                border-top: none;
                border-right: none;
            }
            &:nth-child(3n + 1) {
                border-left: none;
            }
            &:nth-child(3n) {
                border-right: none;
            }
            &:nth-last-child(4) {
                border-bottom: none;
            }
            &:nth-last-child(3) {
                border-bottom: none;
            }
            &:nth-last-child(2) {
                border-bottom: none;
            }
            &:last-child {
                border-bottom: none;
                border-right: none;
            }
        }
        @media(min-width: $breakpoint-desktop-large-min) {
            width: 20%;
            flex: 0 0 20%;
            border: 0.5px solid rgba(0,0,0,0.2);
            margin-top:0;
            padding: 32px 15px;
            &:first-child {
                border-top: none;
                border-left: none;
            }
            &:nth-child(-n + 5) {
                border-top: none;
            }
            &:nth-child(5n) {
                border-right: none;
            }
            &:nth-child(6n) {
                border-left: none;
            }
            &:last-child {
                border-right: none;
            }
            &:nth-last-child(-n + 5){
                border-bottom: none;
            }
        }
        img {
            width: auto;
            margin: 0 auto;
            height: auto;
            max-height: 105px;
        }
        &-image {
            margin-bottom: 30px;
        }
        &-title {
            color: $color-font-additional;
            font-weight: 700;
            font-size: 16px;
            max-width: 205px;
            margin: 0 auto;
        }
    }

    &.reklama-page {
        @media(min-width: $breakpoint-tablet-min) {
            padding: 50px 15px 0;
        }
    }
}
