.avt-section-reklama {
    padding-top: 58px;
    padding-bottom: 58px;
    @media(min-width: $breakpoint-desktop-min) {
        padding-top: 72px;
        padding-bottom: 72px;
    }
    &.dark-section {
        background: #232C45;
        color: #aeb2ba;
        
        .avt-section-description {
            h2.title {
                color: #FFFFFF;
            }
        }
    }
    
    .avt-in-section {
        margin-top: 60px;
    }
    
    &.avt-section-package {}
}
.avt-section-description {
    max-width: 930px;
    margin: 0 auto;
    text-align: center;
    
    h2.title {
        font-weight: 600;
        @media(min-width: $breakpoint-desktop-min) {
            margin-bottom: 32px;
        }
    }
    
    .description {
        font-size: 17px;
        line-height: 30px;
        @media(min-width: $breakpoint-desktop-min) {
            font-size: 20px;
            line-height: 33px;
        }
        p {
            font-size: 17px;
            line-height: 30px;
            @media(min-width: $breakpoint-desktop-min) {
                font-size: 20px;
                line-height: 33px;
            }
        }
        > *:last-child {
            margin-bottom: 0 !important;
        }
    }
}

.avt-reklama-intro {
    overflow: hidden;
    padding-bottom: 20px;
    
    .ellipse-bg {
        position: relative;
        background: #1d212d;
        background: -moz-linear-gradient(-45deg, #1d212d 0%, #232c45 100%);
        background: -webkit-linear-gradient(-45deg, #1d212d 0%,#232c45 100%);
        background: linear-gradient(135deg, #1d212d 0%,#232c45 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d212d', endColorstr='#232c45',GradientType=1 );
        padding-top: 64px;
        padding-bottom: 64px;
        @media(min-width: $breakpoint-desktop-large-min) {
            padding-top: 150px;
            padding-bottom: 150px;
        }
        
        &:after {
            display: block;
            content: '';
            width: 110%;
            height: 50px;
            position: absolute;
            top: 100%;
            left: -5%;
            margin-top: -30px;
            border-bottom-left-radius: 100%;
            border-bottom-right-radius: 100%;
            background: #1f2433;
            background: -moz-linear-gradient(left, #1f2433 0%, #232c45 100%);
            background: -webkit-linear-gradient(left, #1f2433 0%,#232c45 100%);
            background: linear-gradient(to right, #1f2433 0%,#232c45 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f2433', endColorstr='#232c45',GradientType=1 );
        }
    }
    
    h2 {
        font-size: 28px;
        font-weight: 700;
        color: #fff;
        @media(min-width: $breakpoint-desktop-min) {
            font-size: 40px;
        }
    }
    
    .description {
        color: #c7c8cd;
        font-size: 18px;
        margin: 16px 0 0;
        @media(min-width: $breakpoint-desktop-min) {
            font-size: 22px;
            margin: 30px 0 0;
        }
        p {
            color: #c7c8cd;
        }
        > *:last-child {
            margin-bottom: 0 !important;
        }
    }
    
    .img-wrap {
        padding: 25px 15px 0;
    }
    
    img {
        display: block;
        max-width: 100%;
        height: auto;
        @media(min-width: $breakpoint-tablet-min) {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%);
            max-width: 50%;
            padding: 0 0 0 15px;
        }
        @media(min-width: $breakpoint-desktop-large-min) {
            left: calc((100% - 1200px) / 2 + 500px);
            max-width: 55%;
        }
        @media(min-width: $breakpoint-desktop-xxl-min) {
            margin-left: 16px;
        }
    }
    
    .button-intro {
        display: block;
        height: 60px;
        width: 256px;
        margin: 24px auto 0;
        border: $border-btn-additional;
        border-radius: 2px;
        line-height: 56px;
        color: $color-blue-dark;
        text-align: center;
        position: relative;
        transition: color .25s ease 0s;
        font-weight: bold;
        font-size: 15px;
        text-decoration: none;
        @media(min-width: $breakpoint-tablet-min) {
            margin: 36px 0 0;
        }
        @media(min-width: $breakpoint-desktop-large-min) {
            margin: 56px 0 0;
        }
        span {
            position: relative;
            z-index: 2;
        }
        &:after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            transition: width .25s ease 0s;
        }
        &:hover {
            color: #fff;
            &:after {
                right: 0;
                left: auto;
                width: 0;
            }
        }
    }
}

.avt-magazines {
    position: relative;
    max-width: 1170px;
    margin: 30px auto 0;
    @media(min-width: $breakpoint-desktop-min) {
        margin: 88px auto 0;
    }
    @media(min-width: 1400px) {
        max-width: 1400px;
        padding-left: 115px;
        padding-right: 115px;
    }

    .swiper-slide {
        .img-fluid {
            filter: grayscale(100%);
        }
    }
    
    .avt-magazines-prev,
    .avt-magazines-next {
        display: none;
        position: absolute;
        top: 50%;
        margin-top: -9px;
        width: 80px;
        height: 18px;
        cursor: pointer;
        @media(min-width: 1400px) {
            display: block;
        }
        &.swiper-button-disabled {
            opacity: 0.35;
        }
    }
    
    .avt-magazines-prev {
        background: url('../images/svg/all-packages-prev.svg') no-repeat 50%/100% auto;
        left: 10px;
    }
    .avt-magazines-next {
        background: url('../images/svg/all-packages-next.svg') no-repeat 50%/100% auto;
        right: 10px;
    }
}

.avt-all-packages {
    padding-bottom: 58px;
    @media(min-width: $breakpoint-desktop-min) {
        padding-bottom: 100px;
    }
    
    .swiper-container {
        padding-top: 58px;
        padding-bottom: 73px;
        @media(min-width: $breakpoint-desktop-min) {
            padding-top: 72px;
            padding-bottom: 85px;
        }
    }
    
    .swiper-slide {
        padding: 20px;
        box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.16);
        @media(min-width: $breakpoint-desktop-min) {
            padding: 46px 50px 34px;
        }
    }
    
    .swiper-pagination {
        width: 110px;
        left: 50%;
        margin-left: -55px;
        font-size: 18px;
        line-height: 1;
        font-weight: bold;
        color: #1A1B1C;
        
        span {
            position: absolute;
            top: 0;
            width: 50%;
            &.swiper-pagination-current {
                left: 0;
                text-align: right;
                padding: 0 7px 0 0;
            }
            &.swiper-pagination-total {
                right: 0;
                text-align: left;
                padding: 0 0 0 7px;
            }
        }
    }
    
    .all-packages-prev,
    .all-packages-next {
        position: absolute;
        bottom: 10px;
        left: 50%;
        width: 80px;
        height: 18px;
        cursor: pointer;
        &.swiper-button-disabled {
            opacity: 0.35;
        }
    }
    .all-packages-prev {
        margin-left: - 135px;
        background: url('../images/svg/all-packages-prev.svg') no-repeat 50%/100% auto;
    }
    .all-packages-next {
        margin-left: 55px;
        background: url('../images/svg/all-packages-next.svg') no-repeat 50%/100%;
    }
    
    .all-packages-title {
        margin: 0;
        padding: 0 0 25px;
        border-bottom: 3px solid #D51B19;
        font-size: 30px;
        span {
            text-transform: uppercase;
            font-weight: bold;
        }
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 36px;
        }
    }
    
    .order-package {
        display: block;
        margin: 30px auto 0;
        max-width: 330px;
        line-height: 50px;
        background: #D51B19;
        border-radius: 3px;
        text-transform: uppercase;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        text-decoration: none;
        @media(min-width: $breakpoint-desktop-min) {
            margin: 40px auto 0;
            line-height: 70px;
        }
    }
    
    .avt-section-description {
        margin-top: 20px;
    }
}

.avt-data-2 {
    padding: 55px 0 76px;
    background: #232c45;
    h2 {
        font-size: 22px;
        color: #fff;
        text-align: center;
        font-weight: 600;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 28px;
        }
        @media(min-width: $breakpoint-desktop-min) {
            font-size: 35px;
        }
    }
    
    .data-text {
        max-width: 922px;
        margin: 16px auto 0;
        font-size: 16px;
        color: #8f9ea9;
        text-align: center;
        p {
            font-size: 16px;
        }
        
        >*:last-child {
            margin-bottom: 0 !important;
        }
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 20px;
            p {
                font-size: 20px;
            }
        }
    }
    
    .count {
        margin-top: 34px;
        li {
            padding: 2px 0;
            .in {
                display: block;
                height: 100%;
                padding: 14px 15px 15px;
                background: #fff;
                border-radius: 5px;
                box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.25);
                text-align: center;
                font-size: 15px;
                color: #111;
            }
            .title {
                display: block;
                color: #FF0000;
                font-size: 13px;
                font-weight: bold;
                text-transform: uppercase;
            }
            .num {
                display: block;
                font-size: 28px;
                line-height: 35px;
                font-weight: bold;
            }
        }
        @media(min-width: $breakpoint-tablet-min) {
            display: flex;
            margin-left: -2px;
            margin-right: -2px;
            li {
                padding: 0 2px;
                flex: 0 0 20%;
                max-width: 20%;
                .num {
                    font-size: 40px;
                    line-height: 55px;
                }
            }
        }
    }
    
    .border-top,
    .border-bottom{
        position: relative;
        height: 22px;
        border: 1px solid #96989e;
        &:after {
            display: block;
            content: '';
            width: 1px;
            height: 20px;
            background: #96989e;
            position: absolute;
            left: 50%;
        }
    }
    .border-top {
        margin: 60px 225px 0;
        border-bottom: none;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        &:after {
            bottom: 100%;
        }
    }
    
    .border-bottom {
        margin: 0 225px;
        border-top: none;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        &:after {
            top: 100%;
        }
    }
}

.avt-section-statistics {
    &.reklama-page {
        background: none;
        @media(min-width: $breakpoint-desktop-large-min) {
            min-height: 600px;
        }
    }
}

.avt-package {
    @media(min-width: $breakpoint-desktop-min) {
        margin: 0 -1px;
    }
    
    .filter-item {
        font-weight: 600;
        + .filter-item {
            margin: 20px 0 0;
        }
        @media(min-width: $breakpoint-desktop-min) {
            max-width: 291px;
            width: 25%;
            float: left;
            padding: 0 1px;
            + .filter-item {
                margin: 0;
            }
            &:nth-child(1) {
                .group-button {
                    .label {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }
                }
            }
            &:nth-child(4) {
                .group-button {
                    .show-offer {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
            }
        }
        
        .group-button {
            position: relative;
            height: 90px;
            max-width: 291px;
            margin: 0 auto ;
            font-size: 15px;
            text-align: center;
            color: #fff;
            
            .label {
                position: relative;
                background: #5a6174;
                height: 100%;
                line-height: 90px;
                cursor: pointer;
                transition: background 0.25s ease 0s;
                
                .text {
                    position: relative;
                    z-index: 1;
                }
                
                &.checked {
                    background: rgba(54, 195, 152, 0.25);
                    &:hover:not(.impossible) {
                        background: darken(rgba(54, 195, 152, 0.25), 4%);
                    }
                }
                
                
                .anim-red {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: #950808;
                    opacity: 0;
                    &.no-checked {
                        animation: no-checked 0.5s ease forwards;
                    }
                }
                
                @keyframes no-checked {
                    0%
                    {
                        opacity: 0;
                    }
                    25%
                    {
                        opacity: 0.3;
                    }
                    50%
                    {
                        opacity: 0;
                    }
                    75%
                    {
                        opacity: 0.3;
                    }
                    100%
                    {
                        opacity: 0;
                    }
                }
                
                &.impossible {
                    cursor: default;
                }
                
                &:hover:not(.impossible) {
                    background: darken(#5a6174, 2%);
                }
            }
            
            .show-offer {
                background: #D51B19;
                height: 100%;
                line-height: 90px;
                font-size: 17px;
                font-weight: bold;
                text-transform: uppercase;
                cursor: pointer;
                transition: background 0.25s ease 0s;
                
                &.showed {
                    background: #36C398;
                    cursor: default;
                    
                    &:hover {
                        background: #36C398;
                    }
                }
                
                &:hover {
                    background: darken(#D51B19, 3%);
                }
            }
        }
        
        .open-box {
            display: none;
            opacity: 0;
            position: absolute;
            z-index: 5;
            left: -8px;
            right: -8px;
            top: 50%;
            transform: translateY(-50%) scale(0.9);
            background: #a2a6b5;
            border-radius: 3px;
            box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.7);
            height: 412px;
            max-height: 80vh;
            padding: 28px 0 66px;
            &.opened {
                display: block;
                animation: open 0.25s ease forwards;
            }
            &.close {
                display: block;
                animation: close 0.25s ease forwards;
            }
            
            @keyframes open {
                0%
                {
                    opacity: 0;
                    transform: translateY(-50%) scale(0.9);
                }
                100%
                {
                    opacity: 1;
                    transform: translateY(-50%) scale(1);
                }
            }
            
            @keyframes close {
                0%
                {
                    opacity: 1;
                    transform: translateY(-50%) scale(1);
                }
                100%
                {
                    opacity: 0;
                    transform: translateY(-50%) scale(0.9);
                }
            }
            
            .checkbox-area {
                padding: 0 45px 0 23px;
            }
            
            label {
                position: relative;
                display: block;
                float: left;
                clear: left;
                padding: 2px;
                margin: 0;
                + label {
                    margin: 12px 0 0;
                }
                input {
                    position: absolute;
                    visibility: hidden;
                }
                span.input {
                    position: relative;
                    display: block;
                    padding: 0 14px;
                    border-radius: 3px;
                    color: #fff;
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 600;
                    text-transform: uppercase;
                    user-select: none;
                    transition: padding 0s linear 0.4s;
                }
                span.close {
                    position: absolute;
                    right: 8px;
                    top: 50%;
                    margin-top: -9px;
                    display: block;
                    opacity: 0;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    line-height: 1;
                    transition: opacity 0.4s linear 0s;
                }
                .b {
                    background: #fff;
                }
                .anim-bg {
                    background: rgba(231, 231, 231, 0.2);
                }
                input:checked + span.input {
                    transition: padding 0s linear 0s;
                    padding: 0 36px 0 14px;
                    span.close {
                        opacity: 1;
                    }
                }
            }
            
            .button-wrap {
                position: absolute;
                bottom: 13px;
                right: 40px;
                left: 40px;
            }
            
            button {
                display: block;
                height: 40px;
                width: 100%;
                background: #fff;
                border: none;
                border-radius: 3px;
                font-size: 15px;
                font-weight: bold;
                color: #A2A6B5;
                text-transform: uppercase;
                outline: none;
                transition: background 0.25s ease 0s;
                
                &:hover {
                    background: darken( #fff, 2% );
                }
            }
            
            .button {
                display: block;
                height: 40px;
                position: absolute;
                bottom: 13px;
                left: 40px;
                right: 40px;
                background: #fff;
                border: none;
                border-radius: 3px;
                font-size: 15px;
                font-weight: bold;
                color: #A2A6B5;
                text-transform: uppercase;
                outline: none;
            }
        }
        
        .selected-row {
            max-width: 620px;
            margin: 25px auto 30px;
            text-align: center;
            &.hide {
                display: none;
            }
            @media(min-width: $breakpoint-tablet-min) {
                margin: 15px auto 30px;
            }
            @media(min-width: $breakpoint-desktop-min) {
                margin: 20px auto 0;
            }
        }

        .selected-item {
            position: relative;
            max-width: 200px;
            width: 100%;
            height: 48px;
            padding: 0 30px 0 20px;
            margin: 10px auto 0;
            border: 1px solid #fff;
            border-radius: 3px;
            line-height: 46px;
            text-transform: uppercase;
            color: #fff;
            font-size: 12px;
            text-align: left;
            @media(min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max) {
                display: inline-block;
                + .selected-item {
                    margin-left: 10px;
                }                
                &:nth-child(3n+1) {
                    margin-left: 0;
                }
            }
            span.remove-selected-item {
                display: block;
                position: absolute;
                z-index: 4;
                right: 0;
                top: 50%;
                margin-top: -13px;
                width: 26px;
                height: 26px;
                border-radius: 50%;
                line-height: 1;
                cursor: pointer;
                
                svg {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin: -8px 0 0 -8px;
                }
            }
        }
    }
    
    .offer-page {
        display: none;
        &.show {
            display: block;
        }
    }
    
    .package-offer {
        max-width: 872px;
        margin: 65px auto 0;
        color: #fff;
        
        .big-line {
            padding: 0 0 22px;
            border-bottom: 3px solid #fff;
            @media(min-width: $breakpoint-tablet-min) {
                padding: 0 0 42px;
            }
        }
        
        .edit-offer {
            display: block;
            margin: 0 auto;
            width: 166px;
            height: 40px;
            border: 1px solid #fff;
            border-radius: 3px;
            text-align: center;
            font-weight: 700;
            background: transparent;
            color: #fff;
            outline: none;
            cursor: pointer;
            @media(min-width: $breakpoint-tablet-min) {
                float: right;
            }
        }
        
        .title {
            margin: 30px 0 0;
            font-size: 28px;
            text-align: center;
            @media(min-width: $breakpoint-tablet-min) {
                margin: 0;
                font-size: 34px;
                line-height: 40px;
                float: left;
            }
        }
    }
    
    .persone-data {
        max-width: 872px;
        margin: 0 auto;
        color: #fff;
        
        .input-title {
            font-size: 20px;
            font-weight: 500;
            margin: 30px 0 0;
            @media(min-width: $breakpoint-tablet-min) {
                
            }
        }
        
        input[type='text'],
        input[type='email'] {
            display: block;
            width: 100%;
            height: 50px;
            background: #29324a;
            border: 1px solid #40485e;
            margin: 18px 0 0;
            padding: 0 15px;
            font-size: 20px;
            color: #fff;
            outline: none;
            @media(min-width: $breakpoint-tablet-min) {
                height: 70px;
                padding: 0 20px;
                margin: 28px 0 0;
            }
        }
        
        textarea {
            display: block;
            width: 100%;
            height: 226px;
            background: #29324a;
            border: 1px solid #40485e;
            margin: 18px 0 0;
            padding: 6px 15px;
            font-size: 20px;
            color: #fff;
            outline: none;
            resize: none;
            @media(min-width: $breakpoint-tablet-min) {
                padding: 6px 20px;
                margin: 28px 0 0;
            }
        }
        
        button[type='submit'] {
            display: block;
            width: 100%;
            margin: 46px 0 0;
            border-radius: 3px;
            border: none;
            background: #D51B19;
            color: #fff;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: bold;
            height: 70px;
            cursor: pointer;
            @media(min-width: $breakpoint-tablet-min) {
                height: 90px;
            }
            &.submitted {
                background: #36c398;
            }
            &:focus {
                outline: none;
            }
        }
    }
}
/*************** SCROLLBAR ***************/
/*************** SCROLLBAR BASE CSS ***************/
.scroll-wrapper {
    overflow: hidden !important;
    padding: 0 !important;
    position: relative;
    max-height: 100%;

    & > .scroll-content {
        border: none !important;
        box-sizing: content-box !important;
        height: auto;
        left: 0;
        margin: 0;
        max-height: none;
        max-width: none !important;
        overflow: scroll !important;
        padding: 0;
        position: relative !important;
        top: 0;
        width: auto !important;

        &::-webkit-scrollbar {
            height: 0;
            width: 0;
        }
    }
    &.scroll--rtl {
        direction: rtl;
    }
}

.scroll-element {
    box-sizing: content-box;
    display: none;

    div {
        box-sizing: content-box;
    }
    .scroll-bar,
    .scroll-arrow {
        cursor: default;
    }

    &.scroll-x.scroll-scrollx_visible,
    &.scroll-y.scroll-scrolly_visible {
        display: block;
    }
}

.scroll-textarea {
    border: 1px solid #cccccc;
    border-top-color: #999999;

    & > .scroll-content {
        overflow: hidden !important;

        & > textarea {
            border: none !important;
            box-sizing: border-box;
            height: 100% !important;
            margin: 0;
            max-height: none !important;
            max-width: none !important;
            overflow: scroll !important;
            outline: none;
            padding: 2px;
            position: relative !important;
            top: 0;
            width: 100% !important;

            &::-webkit-scrollbar {
                height: 0;
                width: 0;
            }
        }
    }
}

/*************** SCROLLBAR RAIL ***************/

.scrollbar-rail > .scroll-element,
.scrollbar-rail > .scroll-element div
{
    border: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    z-index: 10;
}

.scrollbar-rail > .scroll-element {}

.scrollbar-rail > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
}

.scrollbar-rail > .scroll-element .scroll-element_size {
    background-color: #b7bac5;
}

.scrollbar-rail > .scroll-element .scroll-element_outer:hover .scroll-element_size {
    background-color: #c7cad7;
}

.scrollbar-rail > .scroll-element.scroll-x {
    bottom: 0;
    height: 12px;
    left: 0;
    min-width: 100%;
    padding: 3px 0 2px;
    width: 100%;
}

.scrollbar-rail > .scroll-element.scroll-y {
    height: 100%;
    min-height: 100%;
    padding: 0 2px 0 3px;
    right: 30px;
    top: 0;
    width: 7px;
}

.scrollbar-rail > .scroll-element .scroll-bar {
    background-color: #fefefe;
    border-radius: 2px;
}

.scrollbar-rail > .scroll-element .scroll-element_outer:hover .scroll-bar {
}

/* scrollbar height/width & offset from container borders */

.scrollbar-rail > .scroll-content.scroll-scrolly_visible {}
.scrollbar-rail > .scroll-content.scroll-scrollx_visible { margin-top: 17px; top: -17px; }

.scrollbar-rail > .scroll-element.scroll-x .scroll-bar { height: 10px; min-width: 10px; top: 1px; }
.scrollbar-rail > .scroll-element.scroll-y .scroll-bar { left: 3px; min-height: 10px; width: 5px; }

.scrollbar-rail > .scroll-element.scroll-x .scroll-element_outer { height: 15px; left: 5px; }
.scrollbar-rail > .scroll-element.scroll-x .scroll-element_size { height: 2px; left: -10px; top: 5px; }

.scrollbar-rail > .scroll-element.scroll-y .scroll-element_outer { top: 5px; width: 15px; }
.scrollbar-rail > .scroll-element.scroll-y .scroll-element_size { left: 5px; top: -10px; width: 1px; }

/* update scrollbar offset if both scrolls are visible */

.scrollbar-rail > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size { left: -25px; }
.scrollbar-rail > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size { top: -25px; }

.scrollbar-rail > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track { left: -25px; }
.scrollbar-rail > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track { top: -25px; }

/*************** SCROLLBAR END ***************/

.avt-target-group {
    background: #DBE3E5;
    
    .swiper-container {
        padding: 0 40px 70px;
        margin: 45px auto 0;
        .swiper-slide {
            transform: scale(0.8);
            transition: 0.3s;
            &.swiper-slide-active {
                transform: scale(1.0);
            }
            &.swiper-slide-duplicate-active {
                transform: scale(1.0);
            }
            &.swiper-slide-prev {
                margin-left: -20px;
                margin-right: 20px;
            }
            &.swiper-slide-next {
                margin-left: 20px;
                margin-right: -20px;
            }
            &.swiper-slide-duplicate-prev {
                margin-left: -20px;
                margin-right: 20px;
            }
            &.swiper-slide-duplicate-next {
                margin-left: 20px;
                margin-right: -20px;
            }
        }
    }
    
    .swiper-pagination {
        width: 110px;
        left: 50%;
        margin-left: -55px;
        font-size: 18px;
        line-height: 1;
        font-weight: bold;
        color: #1A1B1C;
        
        span {
            position: absolute;
            top: 0;
            width: 50%;
            &.swiper-pagination-current {
                left: 0;
                text-align: right;
                padding: 0 7px 0 0;
            }
            &.swiper-pagination-total {
                right: 0;
                text-align: left;
                padding: 0 0 0 7px;
            }
        }
    }
    
    .target-group-prev,
    .target-group-next {
        position: absolute;
        bottom: 10px;
        left: 50%;
        width: 80px;
        height: 18px;
        cursor: pointer;
        &.swiper-button-disabled {
            opacity: 0.35;
        }
    }
    .target-group-prev {
        margin-left: - 135px;
        background: url('../images/svg/all-packages-prev.svg') no-repeat 50%/100% auto;
    }
    .target-group-next {
        margin-left: 55px;
        background: url('../images/svg/all-packages-next.svg') no-repeat 50%/100%;
    }
    
    .slider-item {
        background: #fff;
        border-radius: 20px;
        
        h3.slider-title {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            margin: 0 0 38px;
            font-size: 30px;
            font-weight: bold;
            text-align: center;
            line-height: 120px;
            background: #232C45;
            color: #fff;
            &.swiper-automatyka-title {
                font-size: 28px;
            }
        }
        
        .slider-text {
            font-size: 17px;
            line-height: 33px;
            text-align: center;
            padding: 0 20px;
            @media(min-width: $breakpoint-tablet-min) {
                padding: 0 38px;
            }
            
            > *:last-child {
                margin-bottom: 0 !important;
            }
        }
        
        .two-blocks {
            padding: 16px 20px 35px;
            margin: 0;
            @media(min-width: $breakpoint-tablet-min) {
                padding: 36px 38px 46px;
                display: flex;
            }
            
            li {
                text-align: center;
                margin: 12px 0 0;
                @media(min-width: $breakpoint-tablet-min) {
                    margin: 0;
                    flex: 0 0 50%;
                    max-width: 50%;
                    padding: 14px 0;
                    &:nth-child(1) {
                        border-right: 1px solid #dedede;
                    }
                }
                
                .sub-title {
                    font-weight: bold;
                    text-transform: uppercase;
                    @media(min-width: $breakpoint-tablet-min) {
                        font-size: 14px;
                    }
                }
                
                .num {
                    display: block;
                    color: #D51B19;
                    font-weight: bold;
                    white-space: nowrap;
                    font-size: 36px;
                    line-height: 1;
                    @media(min-width: $breakpoint-tablet-min) {
                        margin: 6px 0 0;
                        font-size: 54px;
                    }
                    
                    span {
                        font-size: 20px;
                        @media(min-width: $breakpoint-tablet-min) {
                            font-size: 25px;
                        }
                    }
                }
            }
        }
    }
}


.avt-case-study {
    text-align: center;
    position: relative;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
    color: #fff;
    @media(min-width: $breakpoint-desktop-min) {
        min-height: 780px;
    }
    
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.3);
    }
    
    .in-case {
        position: relative;
        z-index: 1;
    }
    
    .category {
        font-size: 35px;
        font-weight: 600;
        @media(min-width: $breakpoint-desktop-min) {
            font-size: 24px;
        }
    }
    
    h2 {
        margin: 38px 0 0;
        font-size: 40px;
        @media(min-width: $breakpoint-desktop-min) {
            margin: 68px 0 0;
            font-size: 60px;
        }
    }
    
    .case-text {
        max-width: 970px;
        margin: 38px auto 0;
        color: #b2b6b8;
        font-size: 17px;
        line-height: 30px;
        @media(min-width: $breakpoint-desktop-min) {
            margin: 68px auto 0;
            font-size: 20px;
            line-height: 33px;
        }
        
        > *:last-child {
            margin-bottom: 0 !important;
        }
    }
}

.avt-trust-us {
    .trust-us-swiper {
        margin: 32px auto 0;
    }
    img {
        filter: grayscale(1);
        transition: filter, 0.5s, easy;
        &:hover {
            filter: grayscale(0);
        }
    }
}