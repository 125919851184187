.segments-slider {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    max-width: 1470px;
    margin: 0 auto;
    padding: 0 0 50px;
    @media(min-width: 768px) {
        padding: 35px 15px 50px;
    }
    @media(min-width: 1025px) {
        padding: 75px 15px 100px;
    }
    &__menu {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 30px;
        display: none;
        @media(min-width: 768px) {
            display: flex;
        }
        @media(min-width: 1025px) {
            padding-right: 45px;
            display: block;
            max-width: 345px;
            margin: 0;
        }
        &-item {
            width: 100%;
            min-height: 115px;
            border-radius: 15px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            padding: 25px 20px;
            cursor: pointer;
            max-width: calc(50% - 15px);
            @media(min-width: 1025px) {
                max-width: 100%;
            }
            * {
                pointer-events: none;
            }
            &-icon {
                width: 54px;
                height: 54px;
                border-radius: 50%;
                background-color: $color-white;
                box-shadow: 0 10px 50px rgba(0,0,0,0.16);
                background-position: center;
                background-repeat: no-repeat;
            }
            &-text {
                padding-left: 28px;
                font-size: 16px;
                line-height: 22px;
                font-weight: 700;
                max-width: calc(100% - 82px);
                text-transform: uppercase;
            }
            &[data-id="122"] {
                .segments-slider__menu-item-icon {
                    background-image: url("/templates/main/images/media/icons/home.svg");
                }
            }
            &[data-id="123"] {
                .segments-slider__menu-item-icon {
                    background-image: url("/templates/main/images/media/icons/cog.svg");
                }
            }
            &[data-id="124"] {
                .segments-slider__menu-item-icon {
                    background-image: url("/templates/main/images/media/icons/headphones.svg");
                }
            }
            &[data-id="125"] {
                .segments-slider__menu-item-icon {
                    background-image: url("/templates/main/images/media/icons/camera.svg");
                }
            }
            &[data-id="126"] {
                .segments-slider__menu-item-icon {
                    background-image: url("/templates/main/images/media/icons/zap.svg");
                }
            }
            &[data-id="127"] {
                .segments-slider__menu-item-icon {
                    background-image: url("/templates/main/images/media/icons/user-plus.svg");
                }
            }
            &.active {
                background-color: $color-white;
                box-shadow: 0 15px 50px rgba(0,0,0,0.16);
                .segments-slider__menu-item-icon {
                    background-color: $color-red;
                    box-shadow: 0 10px 20px rgba(211,46,47,0.45);
                }
                &[data-id="122"] {
                    .segments-slider__menu-item-icon {
                        background-image: url("/templates/main/images/media/icons/home-white.svg");
                    }
                }
                &[data-id="123"] {
                    .segments-slider__menu-item-icon {
                        background-image: url("/templates/main/images/media/icons/cog-white.svg");
                    }
                }
                &[data-id="124"] {
                    .segments-slider__menu-item-icon {
                        background-image: url("/templates/main/images/media/icons/headphones-white.svg");
                    }
                }
                &[data-id="125"] {
                    .segments-slider__menu-item-icon {
                        background-image: url("/templates/main/images/media/icons/camera-white.svg");
                    }
                }
                &[data-id="126"] {
                    .segments-slider__menu-item-icon {
                        background-image: url("/templates/main/images/media/icons/zap-white.svg");
                    }
                }
                &[data-id="127"] {
                    .segments-slider__menu-item-icon {
                        background-image: url("/templates/main/images/media/icons/user-plus-white.svg");
                    }
                }
            }

        }
    }
    &__slider {
        width: 100%;
        // background-color: $color-white;
        @media(min-width: 768px) {
            border-radius: 15px;
            box-shadow: 0 50px 99px rgba(0,0,0,0.16);
            padding: 36px 0 66px;
        }
        @media(min-width: 1025px) {
            max-width: calc(100% - 345px);
        }
        &-next, &-prev {
            position: absolute;
            top: 35px;
            bottom: auto;
            margin: auto;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            justify-content: center;
            box-shadow: 0 10px 50px rgba(0,0,0,0.16);
            z-index: 9;
            transition: opacity .2s ease-in-out;
            opacity: 1;
            @media(min-width: 768px) {
                display: none;
            }
            img {
                display: block;
                max-width: 8px;
                height: auto;
            }
            &.swiper-button-disabled {
                opacity: 0;
                pointer-events: none;
            }
        }
        &-prev {
            left: -26px;
            padding-left: 20px;
        }
        &-next {
            right: -26px;
            padding-right: 20px;
        }
    }
    &__item {
        padding: 35px 0 0;
        @media(min-width: 768px) {
            padding: 0 60px;
        }
        &-title {
            text-align: center;
            font-size: 30px;
            line-height: 30px;
            font-weight: 700;
            margin-bottom: 40px;
            .segments-slider__menu-item-icon {
                background-color: $color-red;
                margin: 0 auto 30px;
                @media(min-width: 768px) {
                    display: none;
                }
            }
            &[data-id="122"] {
                .segments-slider__menu-item-icon {
                    background-image: url("/templates/main/images/media/icons/home-white.svg");
                }
            }
            &[data-id="123"] {
                .segments-slider__menu-item-icon {
                    background-image: url("/templates/main/images/media/icons/cog-white.svg");
                }
            }
            &[data-id="124"] {
                .segments-slider__menu-item-icon {
                    background-image: url("/templates/main/images/media/icons/headphones-white.svg");
                }
            }
            &[data-id="125"] {
                .segments-slider__menu-item-icon {
                    background-image: url("/templates/main/images/media/icons/camera-white.svg");
                }
            }
            &[data-id="126"] {
                .segments-slider__menu-item-icon {
                    background-image: url("/templates/main/images/media/icons/zap-white.svg");
                }
            }
            &[data-id="127"] {
                .segments-slider__menu-item-icon {
                    background-image: url("/templates/main/images/media/icons/user-plus-white.svg");
                }
            }
        }
        &-publishing {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 50px;
            border-top: 1px solid #E4E4E4;
            @media(min-width: 768px) {
                border-top: none;
            }
            &-logo {
                min-height: 120px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                justify-content: center;
                text-align: center;
                max-width: 50%;
                width: 100%;
                border-bottom: 1px solid #E4E4E4;
                @media(min-width: 768px) {
                    border: 1px solid #E4E4E4;
                    border-left: none;
                    max-width: 25%;
                }
                &:first-child {
                    @media(min-width: 768px) {
                        border-top-left-radius: 10px;
                        border-left: 1px solid #E4E4E4;
                    }
                }
                &:nth-child(4) {
                    @media(min-width: 768px) {
                        border-top-right-radius: 10px;
                    }
                }
                &:nth-child(n + 5) {
                    @media(min-width: 768px) {
                        border-top: none;
                    }
                }
                &:nth-child(5) {
                    @media(min-width: 768px) {
                        border-bottom-left-radius: 10px;
                        border-left: 1px solid #E4E4E4;
                    }
                }
                &:nth-child(odd) {
                    border-right: 1px solid #E4E4E4;
                }
                img {
                    max-width: 70%;
                    height: auto;
                    display: block;
                }
            }
        }
        &-description {
            font-size: 18px;
            line-height: 26px;
            padding: 0 15px 45px;
            @media(min-width: 768px) {
                border-bottom: 2px solid rgba(37,41,47,0.15);
                padding: 0 0 45px;
            }
        }
        &-fields {
            display: flex;
            flex-wrap: wrap;
            max-width: 890px;
            margin: 0 auto;
            padding: 0 15px;
            @media(min-width: 768px) {
                padding: 0;
            }
        }
        &-field {
            width: 100%;
            @media(min-width: 576px) {
                max-width: 50%;
            }
            @media(min-width: 768px) {
                max-width: calc(100% / 3);
            }
            &-value {
                font-size: 43px;
                font-weight: 700;
            }
        }
    }
}
