.avt-anim-bg {
    overflow: hidden;
    position: relative;
    
    .anim-text {
        position: relative;
        z-index: 2;
    }
    
    .anim-bg {
        position: absolute;
        top: 50%;
        left: 50px;
        width: 0;
        height: 0;
        background: rgba(123, 123, 123, 0.1);
        transform: translate(-50%, -50%);
        border-radius: 40%;
        &.pressed {
            animation: animate 0.4s linear forwards;
        }
    }
}

.avt-anim-bg-nav {
    li {
        a {
            overflow: hidden;
            position: relative;
            
            .anim-bg {
                position: absolute;
                top: 50%;
                left: 50px;
                width: 0;
                height: 0;
                background: rgba(123, 123, 123, 0.1);
                transform: translate(-50%, -50%);
                border-radius: 40%;
                &.pressed {
                    animation: animate 0.4s linear forwards;
                }
            }
        }
    }
}

@keyframes animate {
    0%
    {
        opacity: 0;
        width: 0;
        height: 0;
        border-radius: 40%;
    }
    10% {
        opacity: 1;
    }
    80%
    {
        border-radius: 10%;
    }
    100%
    {
        width: 200%;
        height: 200%;
        border-radius: 0;
    }
}