.avt-d-table-slide {
    display: table;
    width: 100%;
    
    .d-table-cell {
        height: 54px;
        vertical-align: middle;
    }
    
    img {
        max-height: 100%;
        width: auto;
        height: auto;
        max-width: 100%;
    }
}



