.products-list {

    .product-item {
        img {
            margin: 0 auto 10px;
            max-width: 60px;
        }
    }
}
.avt-reklama-grid {
    &__item {
        &-icon {
            height: 61px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            width: 100%;
            img {
                margin: 0 auto 10px;
                max-width: 60px;
                max-height: 100%;
                width: auto;
                height: auto;
            }
        }
    }
    .d-table-cell {
        height: 100%;
    }
}
