.main-top {
    position: relative;
    background-image: url("/templates/main/images/media/main-top-bg.png");
    background-repeat: no-repeat;
    overflow: hidden;
    background-position: center -150px;
    padding: 140px 15px 40px;
    @media(min-width: 576px) {
        background-position: center -100px;
    }
    @media(min-width: 768px) {
        padding: 170px 15px 40px;
        background-position: center top;
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        height: auto;
    }
    &__wrap {
        position: relative;
        z-index: 3;
        text-align: center;
        max-width: 1100px;
        margin: 0 auto;
    }
    &__title {
        font-weight: 700;
        font-size: 23px;
        line-height: 32px;
        margin-bottom: 20px;
        @media(min-width: 576px) {
            font-size: 30px;
            line-height: 35px;
        }
        @media(min-width: 768px) {
            font-size: 40px;
            line-height: 45px;
            margin-bottom: 40px;
        }
        @media(min-width: 1025px) {
            font-size: 50px;
            line-height: 55px;
        }
        @media(min-width: 1200px) {
            font-size: 60px;
            line-height: 65px;
        }
    }
    &__subtitle {
        letter-spacing: 0.15em;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
        @media(min-width: 768px) {
            margin-bottom: 35px;
            font-size: 20px;
            line-height: 26px;
        }
    }
    &__img {
        margin-right: 0;
        display: block;
        max-width: 100%;
        height: auto;
        margin-left: 30px;
        @media(min-width: 576px) {
            margin-left: 70px;
        }
        @media(min-width: 768px) {
            margin-left: 50px;
        }
        @media(min-width: 1025px) {
            margin-left: auto;
        }
    }
    &__btn {
        font-size: 16px;
        color: $color-font-main;
        background-color: $color-white;
        padding: 18px 44px;
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-weight: 700;
        text-align: center;
        border-radius: 4px;
        box-shadow: 0 30px 50px rgba(0,0,0,0.32);
        cursor: pointer;
        text-decoration: none;
        margin-bottom: 30px;
        @media(min-width: 576px) {
            margin-bottom: 60px;
        }
        &:hover {
            color: $color-font-main;
            text-decoration: none;
        }
    }
}
