.media-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    background-color: transparent;
    height: auto;
    font-family: "Switzer", sans-serif;
    img {
        display: block;
        max-width: 100%;
        height: auto;
    }
    &__link {
        text-decoration: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        &--main {
            border: 2px solid $color-white;
            border-radius: 4px;
            min-height: 48px;
            padding: 5px 23px;
            font-size: 16px;
            font-weight: 700;
            transition: background-color .2s ease-in-out;
            box-shadow: 0 30px 50px rgba(0,0,0,0.16);
            color: $color-font-main;
            img {
                margin-right: 11px;
            }
            &:hover {
                color: $color-font-main;
                background-color: $color-white;
            }
        }
        &--logo {
            img {
                box-shadow: 0 20px 50px rgba(0,0,0,0.16);
            }
        }
        &--contact {
            overflow: hidden;
            border-radius: 4px;
            transition: background-color .2s ease-in-out;
            &:hover {
                color: $color-font-main;
                background-color: $color-white;
            }
        }
        &--main,
        &--contact {
            display: none;
            @media(min-width: 768px) {
                display: flex;
            }
        }
        &-left,
        &-right {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            min-height: 48px;
            border: 2px solid $color-white;
            font-size: 16px;
            font-weight: 700;
        }
        &-left {
            padding: 5px 20px;
            background-color: $color-white;
        }
        &-right {
            padding: 5px 18px;
            img {
                margin-right: 7px;
            }
        }
    }
    &__container {
        padding: 0 15px;
        margin: 0 auto;
        max-width: 1500px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        @media(min-width: 768px) {
            justify-content: space-between;
        }
    }
}
