.media {
    color: $color-white;
    background-image: url("/templates/main/images/media/media-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
    background-position: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    height: 380px;
    @media(min-width: 576px) {
        height: 450px;
    }
    @media(min-width: 768px) {
        height: 500px;
    }
    @media(min-width: 1025px) {
        background-position: right center;
    }
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0,0,0,0.5);
        @media(min-width: 1025px) {
            background-color: rgba(0,0,0,0.3);
        }
        @media(min-width: 1440px) {
            background-color: rgba(0,0,0,0.1);
        }
    }
    &.segments {
        background-image: url("/templates/main/images/media/background-segments.jpg");
        .media__wrap {
            &-text {
                @media(min-width: 768px) {
                    max-width: 720px;
                }
            }
        }
    }
    &.groups {
        background-image: url("/templates/main/images/media/background-groups.jpg");
        .media__wrap {
            &-text {
                @media(min-width: 768px) {
                    max-width: 845px;
                }
            }
        }
    }
    &.products {
        background-image: url("/templates/main/images/media/background-products.jpg");
        .media__wrap {
            &-text {
                @media(min-width: 768px) {
                    max-width: 845px;
                }
            }
        }
    }
    &__wrap {
        max-width: 1470px;
        margin: 0 auto;
        position: relative;
        width: 100%;
        z-index: 3;
        padding: 0 15px;
        text-align: center;
        @media(min-width: 768px) {
            padding: 0 45px;
            text-align: left;
        }
        @media(min-width: 1470px) {
            padding: 0 15px;
        }
        &-text {
            @media(min-width: 768px) {
                max-width: 470px;
            }
        }
    }
    &__title {
        margin-bottom: 25px;
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        @media(min-width: 576px) {
            font-size: 30px;
            line-height: 35px;
        }
        @media(min-width: 768px) {
            font-size: 40px;
            line-height: 45px;
        }
    }
    &__text {
        font-size: 15px;
        line-height: 22px;
        @media(min-width: 576px) {
            font-size: 16px;
            line-height: 24px;
        }
        @media(min-width: 768px) {
            font-size: 18px;
            line-height: 26px;
        }
    }
}
