.avt-catalog-btn{
    background-color: $bg-second;
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .avt-az, .avt-title, .avt-marks{
        display: block;
    }
    .avt-az{
        color: $color-font-additional;
        font-size: 60px;
        font-weight: 700;
        line-height: 60px;
    }
    .avt-title{
        font-size: 17px;
        font-weight: 700;
        margin-bottom: 24px;
    }
    .avt-marks{
        position: absolute;
        bottom: 16px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}
