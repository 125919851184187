.slider-frame {
    @media(min-width: $breakpoint-desktop-large-min) {
        position: relative;
        margin: 0 auto;
        overflow: hidden;
        background-color: $bg-second;
    }
    &.avt-portfolio {
        background-color: $bg-home;
    }
}

.slider-frame .slider-container {
    @media(min-width: $breakpoint-desktop-large-min) {
        transition: margin-left .4s ease, left .5s ease;
        position: absolute;
        left: 0;
        background-color: $bg-main;
        padding-bottom: 50px;
    }
}
.slider-frame .slider-container .slide {
    background: $bg-photos;
    position: relative;
    display: block;
    overflow: hidden;
    @media(min-width: $breakpoint-tablet-min) {
        width: calc(100% / 3);
        float: left;
    }
    @media(min-width: $breakpoint-desktop-large-min) {
        float: left;
        position: relative;
        top: 0;
        transition: width .4s ease, height .4s ease, top .4s ease, box-shadow .6s ease;
        overflow: hidden;
    }
    .category-image {
        display: block;
        width: 100%;
    }
    .title {
        position: absolute;
        color: $color-font-second;
        top:0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        text-transform: uppercase;
        font-weight: 700;
        padding: 0 15px;
        @media(min-width: $breakpoint-desktop-large-min) and (max-width: $breakpoint-desktop-large-max) {
            font-size: 11px;
        }
        @media(min-width: $breakpoint-desktop-large-max) and (max-width: 1700px) {
            font-size: 13px;
        }
    }
}
