.avt-statistic-box {
    text-align: right;
    border-bottom: 2px solid rgba(0,0,0,0.6);
    padding-top: 16px;
    padding-bottom: 8px;
    @media(min-width: $breakpoint-tablet-min) {
        margin: 50px 0;
    }
    @media(min-width: $breakpoint-tablet-min) {
        padding-left: 30px;
        padding-right: 30px;
        border-bottom: none;
    }
    &.reklama-page {
        border-bottom: 3px solid #D51B19;
        padding-bottom: 16px;
        @media(min-width: $breakpoint-tablet-min) {
            margin: 0 0 50px;
            border-bottom: none;
        }
        @media(min-width: $breakpoint-desktop-large-min) {
            margin: 50px 0;
        }
        @media(min-width: $breakpoint-desktop-min) {
            border-bottom: none;
        }
        .avt-title {
            font-size: 18px;
            @media(min-width: $breakpoint-tablet-min) {
                font-size: 13px;
            }
            @media(min-width: $breakpoint-desktop-min) {
                font-size: 14px;
            }
            @media(min-width: $breakpoint-desktop-large-min) {
                font-size: 18px;
            }
            &:after {
                @media(min-width: $breakpoint-tablet-min) {
                    height: 3px;
                    bottom: 50px;
                    background-color: #D51B19;
                    width: 260px;
                }
                @media(min-width: $breakpoint-desktop-min) {
                    width: 310px;
                }
            }
        }
        .avt-subtitle {
            font-size: 15px;
            @media(min-width: $breakpoint-tablet-min) {
                font-size: 11px;
            }
            @media(min-width: $breakpoint-desktop-min) {
                font-size: 12px;
            }
            @media(min-width: $breakpoint-desktop-large-min) {
                font-size: 14px;
            }
            @media(min-width: $breakpoint-desktop-xxl-min) {
                font-size: 15px;
            }
        }
    }

    .avt-number-box {
        padding-bottom: 5px;
        .avt-number, .avt-word {
            color: $color-font-additional;
            font-size: 45px;
            font-weight: 700;
            line-height: 50px;
        }
        .avt-word {
            color: $color-font-additional;
        }
        &.avt-main {
            @media(min-width: $breakpoint-desktop-large-min){
                .avt-number {
                    line-height: 80px;
                    font-size: 90px;
                }
                .avt-word {
                    font-size: 60px;
                }
            }
        }
        &.reklama-page {
            .avt-number, .avt-word {
                color: #fff;
            }
            @media(min-width: $breakpoint-tablet-min){
                .avt-number, .avt-word {
                    font-size: 60px;
                    color: #fff;
                }
            }
        }
    }
    
    .avt-title {
        font-size: 19px;
        font-weight: 600;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 12px;
        }
        @media(min-width: $breakpoint-desktop-min) {
            font-size: 14px;
        }
        @media(min-width: $breakpoint-desktop-large-min) {
            font-size: 17px;
        }
        @media(min-width: $breakpoint-desktop-xxl-min) {
            font-size: 19px;
        }
        &:after {
            @media(min-width: $breakpoint-tablet-min) {
                content: "";
                display: block;
                margin: 4px 0;
                height: 2px;
                width: 250px;
                bottom: 50px;
                background-color: $color-black;
            }
            @media(min-width: $breakpoint-desktop-min) {
                width: 315px;
            }
        }
        &.reklama-page {
            color: $color-gray-dark;
        }
    }
    .avt-subtitle {
        font-size: 16px;
        font-weight: 300;
        color: #9a9a9a;
        @media(min-width: $breakpoint-tablet-min) {
            font-size: 11px;
        }
        @media(min-width: $breakpoint-desktop-min) {
            font-size: 12px;
        }
        @media(min-width: $breakpoint-desktop-large-min) {
            font-size: 15px;
        }
        @media(min-width: $breakpoint-desktop-xxl-min) {
            font-size: 16px;
        }
    }
    &.avt-left {
        @media(min-width: $breakpoint-tablet-min) {
            text-align: right;
        }
        .avt-title {
            &:after {
                @media(min-width:  $breakpoint-tablet-min) {
                    float: right;
                }
            }
        }
    }
    &.avt-right{
        @media(min-width: $breakpoint-tablet-min){
            text-align: left;
        }
    }
}
