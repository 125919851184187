.avt-button {
    margin: 0;
    padding: 0;
    display: block;
    font-size: 13px;
    font-weight: 700;
    height: 60px;
    line-height: 60px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    position: relative;
    span {
        z-index: 999;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        transition: color .25s ease 0s;
    }
    &:after{
        display: block;
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        width: 0;
        transition: width .25s ease 0s;
        z-index: 1;
    }
    &:hover {
        &:after{
            left: 0;
            right: auto;
            width: 100%;
        }
    }
    svg {
        transition: fill .25s ease 0s;
    }
    img,svg {
        max-width: 15px;
        margin-left: 5px;
    }
    &:link, &:hover {
        text-decoration: none;
    }
    &.avt-btn-sm {
        max-width: 220px;
    }
    &.avt-btn-md {
        max-width: 300px;
    }
    &.avt-btn-lg {
        max-width: 390px;
    }
}

.avt-button-main {
    @extend .avt-button;
    background-color: $bg-button-main; //Czerwony
    color: $color-font-second; //Bialy
    svg{
        fill: $color-btn-additional;
    }
    span {
        color: $color-font-second; //Bialy
    }
    &:hover, &:link {
        color: $color-font-second;
    }
    &:after{
        background: $color-black;
    }
    &.avt-btn-border{
        border: $border-btn-main;
        background: transparent;
        color: $color-btn-main;
        svg{
            fill: $color-btn-main;
        }
        &:hover, &:link {
            color: $color-btn-main;
        }
    }
}
.avt-button-second{
    @extend .avt-button;
    background-color: $bg-button-second;
    color: $color-btn-additional;
    svg{
        fill: $color-btn-additional;
    }
    span {
        color: $color-font-main;
    }
    &:hover, &:link {
        color: $color-font-second;
    }
    &.avt-btn-border{
        border: $border-btn-second;
        background: transparent;
        color: $color-btn-second;
        svg{
            fill: $color-btn-second;
        }
        span {
            color: $color-black;
        }
        &:hover {
            color: $color-font-second;
            span {
                color: $color-font-second;
            }
            svg {
                fill: $color-white;
            }
        }
        &:after {
            background-color: $color-black;
        }
    }
}
.avt-button-additional{
    @extend .avt-button;
    background-color: $bg-button-additional;
    color: $color-btn-second;
    svg{
        fill: $color-btn-second;
    }
    span {
        color: $color-font-second;
    }
    &:hover, &:link {
        color: $color-font-main;
    }
    &.avt-btn-border{
        border: $border-btn-additional;
        background: transparent;
        color: $color-btn-additional;
        svg{
            fill: $color-btn-additional;
        }
        &:hover {
            span {
                color: $color-font-main;
            }
            svg {
                fill: $color-black;
            }
        }
        &:after {
            background-color: $bg-button-additional;
        }
    }
}
