/* Kolory */

$color-white:       #fff;
$color-black:       #25292f;
$color-black-dark:  #17181A;
$color-black-0:     #000;
$color-gray:        #f2f2f2;
$color-gray-dark:   #ababab;
$color-gray-dark-2: #cecece;
$color-white:       #fff;
$color-red:         #d32e2f;
$color-blue-dark:   #272D43;
$color-blue-light:  rgba(50, 56, 77, 0.95);

/* Kolory tla */
$bg-main:                           $color-white;
$bg-second:                         $color-gray;
$bg-additional:                     $color-black;
$bg-home:                           $color-blue-dark;
$bg-photos:                         $color-black-0;
$bg-header:                         $color-blue-light;

/* Kolory fontow */
$color-font-main:                   $color-black;
$color-font-second:                 $color-white;
$color-font-additional:             $color-red;

/* Kolor tla przyciskow */
$bg-button-main:                    $color-red;
$bg-button-second:                  $color-black;
$bg-button-additional:              $color-white;

/* Kolor fonta w przyciskach */
$color-btn-main:                    $color-red;
$color-btn-second:                  $color-black;
$color-btn-additional:              $color-white;


/* Bordery do przyciskow */
$border-btn-main: 2px solid         $color-red;
$border-btn-second: 2px solid       $color-black;
$border-btn-additional: 2px solid   $color-white;


/* Wysokosc header/nav */
$height-header: 66px;

/* Box shadow dla przyciskow */
$boxshadow-btn: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

/* Gradienty do sliderow */
$gradient-footer-slider: linear-gradient(90deg, rgba(153,218,255,0) 0%, rgba(255,255,255,1) 100%);

/*Breakpointy */
$breakpoint-extra-mobile-max:     575.98px;
$breakpoint-mobile-min:           576px;
$breakpoint-mobile-max:           767.98px;
$breakpoint-tablet-min:           768px;
$breakpoint-tablet-max:           1024.98px;
$breakpoint-desktop-min:          1025px;
$breakpoint-desktop-max:          1199.98px;
$breakpoint-desktop-large-min:    1200px;
$breakpoint-desktop-large-max:    1439.98px;
$breakpoint-desktop-xxl-min:      1440px;
