.avt-isotope {
    background-color: $bg-second;
    font-size: 0;
    .filter-button-group {
        padding: 1px;
        text-align: center;
        position: relative;
        overflow: hidden;
        height: 44px;
        width: 100%;
        transition-duration: 400ms;
        @media(min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max){
            width: 50%;
            float: left;
        }
        @media(min-width: $breakpoint-desktop-min){
            height: auto;
            width: 100%;
        }
        &.open {
            height: auto;
            .avt-filter-btn {
                img{
                    transform: rotate(180deg);
                }
            }
        }
        &.avt-filter-top {
            margin-bottom: 16px;
        }
        &.avt-filter-bot {
            margin-bottom: 56px;
            @media(min-width: $breakpoint-desktop-min){
                display: flex;
                margin: 0 0 56px;
            }
            button{
                flex-grow: 1;
            }
        }
        .avt-filter-btn{
            transition-duration: 400ms;
            position: absolute;
            width: 40px;
            height: 43.1px;
            top: 1px;
            right: 1px;
            background-color: $bg-button-main;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            @media(min-width: $breakpoint-desktop-min){
                display: none;
            }
        }
        button {
            cursor: pointer;
            display: block;
            width: 100%;
            color: $color-font-second;
            background-color: $bg-button-main;
            font-size: 16px;
            border: none;
            min-height: 44px;
            padding: 0 10px;
            border-bottom: 1px solid $color-gray;
            font-weight: 600;
            outline: 0;
            @media(min-width: $breakpoint-desktop-min){
                color: $color-font-main;
                background-color: $bg-main;
                display: inline-block;
                width: auto;
            }
            &:hover, &:active, &:focus {
                @media(min-width: $breakpoint-desktop-min){
                    color: $color-font-additional;
                    outline: 0;
                }
            }
            &.active {
                background-color: $bg-button-second;
                width: calc(100% - 40px);
                @media(min-width: $breakpoint-desktop-min){
                    background-color: $bg-button-main;
                    color: $color-font-second;
                    width: auto;
                }

            }
        }
    }
    .isotope-grid {
        padding-bottom: 120px;
        transition-duration: 400ms;
        clear: both;
        .element-item {
            height: 170px;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            display: flex;
            background-color: $bg-main;
            border: 0.5px solid $color-gray;
            img {
                width: 100%;
            }
            svg {
            }
        }
    }
}
