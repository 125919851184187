.avt-reklama-nav {
    > ul {
        > li {
            text-align: center;
            @media(min-width: $breakpoint-tablet-min) {
                text-align: left;
            }
            
            &.active {
                > a:after {
                    width: 100%;
                }
            }
            
            + li {
                margin: 24px 0 0;
                @media(min-width: $breakpoint-desktop-min) {
                    margin: 28px 0 0;
                }
            }
            > span {
                color: #d51a18;
                display: inline-block;
                position: relative;
                width: 18px;
                height: 16px;
                font-weight: bold;
                line-height: 1;
                font-size: 20px;
                cursor: pointer;
                @media(min-width: $breakpoint-desktop-min) {
                    height: 18px;
                    font-size: 22px;
                }
                
                &:before,
                &:after {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 10px;
                    height: 100%;
                    transition: all 0.35s ease 0s;
                }
                &:after {
                    content: '-';
                    opacity: 0;
                    transform: rotate(-90deg);
                    
                }
                
                &:before {
                    content: '+';
                }
                
                &.open {
                    &:after {
                        opacity: 1;
                        transform: rotate(0);
                    }

                    &:before {
                        opacity: 0;
                        transform: rotate(90deg);
                    }
                }
            }
            
            > a {
                font-size: 20px;
                color: #1A1B1C;
                font-weight: bold;
                line-height: 1;
                text-decoration: none;
                @media(min-width: $breakpoint-desktop-min) {
                    font-size: 22px;
                }
            }
            
            ul {
                @include clearfix();
                @media(min-width: $breakpoint-tablet-min) {
                    padding: 0 0 0 16px;
                }
                
                /*li {
                    margin: 14px 0 0;
                    
                    @media(min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max) {
                        display: inline-block;
                        vertical-align: baseline;
                        margin: 14px 20px 0 0;
                    }
                    @media(min-width: $breakpoint-desktop-min) {
                        padding: 0 0 0 20px;
                    }

                    a {
                        display: inline-block;
                        position: relative;
                        font-size: 17px;
                        line-height: 24px;
                        text-decoration: none;
                        color: $color-font-main;
                        span {
                            position: absolute;
                            z-index: 1;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            width: 0;
                            overflow: hidden;
                            color: #d51a18;
                            white-space: nowrap;
                            background: #F1F1F1;
                            transition: width .25s ease-out 0s;
                        }
                        
                        &:hover {
                            span {
                                width: 100%;
                            }
                        }
                        
                        &:after {
                            display: none !important;
                        }
                    }
                    
                    &.active {
                        padding: 0;
                        
                        a {
                            display: inline-block;
                            padding: 12px 20px 14px;
                            border-radius: 3px;
                            background: #fff;
                            color: #d51a18;
                            @media(min-width: $breakpoint-desktop-min) {
                                display: block;
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                            }
                            
                            span {
                                display: none;
                            }
                        }
                    }
                }*/
                
                li {
                    margin: 7px 0 0;
                    
                    @media(min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max) {
                        display: inline-block;
                        vertical-align: baseline;
                        margin: 7px 10px 0 0;
                    }

                    a {
                        display: inline-block;
                        padding: 12px 20px 14px;
                        font-size: 17px;
                        line-height: 24px;
                        text-decoration: none;
                        color: $color-font-main;
                        @media(min-width: $breakpoint-desktop-min) {
                            display: block;
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                        
                        .anim-bg {
                            background: rgba(255, 255, 255, 0.7);
                        }
                        
                        .nav-text {
                            position: relative;
                            z-index: 2;
                            
                            span {
                                position: absolute;
                                z-index: 1;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                width: 0;
                                overflow: hidden;
                                color: #d51a18;
                                white-space: nowrap;
                                /*background: #F1F1F1;*/
                                transition: width .25s ease-out 0s;
                                text-align: left;
                            }
                        }
                        
                        
                        
                        &:hover {
                            .nav-text {
                                span {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    
                    &.active {
                        
                        a {
                            background: #fff;
                            color: #d51a18;
                            
                            .nav-text {
                                span {
                                    display: none;
                                }
                            }
                            
                            .anim-bg {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}