.avt-card {
    padding: 15px 10px;

    border-radius: 3px;
    box-shadow: 0 14px 28px rgba($color-black, 0.125), 0 10px 10px rgba($color-black, 0.11);
    background-color: $color-white;

    &-caption {
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid $color-gray-dark-2;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        * {
            margin: 0;
            padding: 0;
            font-size: inherit;
            line-height: inherit;
        }
    }
    &-frame {
        display: flex;
        justify-content: center;
    }

    &-stretch { height: 100% }
}
