.avt-section-about {
    background-color: $bg-home;
    .avt-main-header {
        color: $color-font-second;
    }
    .avt-lead-text {
        max-width: 920px;
        color: $color-font-second;
        opacity: 0.5;
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
    }
}
.avt-about-categories {
    text-align: center;
    background-color: $bg-additional;
    font-size: 0;

    .avt-category {
        font-size: 18px;
        font-weight: 700;
        padding: 15px 0;
        a{
            color: $color-font-second;
            text-decoration: none;
        }
        @media(min-width: $breakpoint-tablet-min) {
            display: inline-block;
            font-size: 15px;
            font-weight: 600;
            padding: 15px 35px;
            vertical-align: top;
        }
        &.active {
            background-color: $color-red;
            @media(min-width: $breakpoint-tablet-min) {
                background-color: $bg-additional;
                position: relative;
                overflow: hidden;
                &:after{
                    content: "";
                    display: block;
                    position: absolute;
                    background-color: $bg-main;
                    width: 30px;
                    height: 30px;
                    transform: rotate(45deg);
                    bottom: -25px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    font-size: 0;
                }
            }
        }
    }
}
.avt-about-description {
    .avt-recommended {
        width: 100%;
        margin-bottom: 32px;
        .avt-download {
            margin-top: 0;
        }
    }
    .avt-order-1 {
        @media(max-width: $breakpoint-mobile-max) {
            order: 1;
        }
    }
    .avt-order-2 {
        @media(max-width: $breakpoint-mobile-max) {
            order: 2;
        }
    }
}
.avt-about-netflix {
    background-color: $bg-main;
}
.avt-about-details {

    .avt-about-article {
        line-height: 28px;
    }
    .avt-button-line {
        @media(max-width: $breakpoint-mobile-max) {
            &:after, &:before {
                display: none;
            }
        }

    }
    .avt-button-main {
        margin: 0 auto;
    }
}
.avt-services-box {
    margin: 16px 0;
    text-align: center;
    font-size: 0;
    .row{
        justify-content: center;
    }
    .avt-title-box,.avt-number-box, .avt-image-box {
        padding: 0 15px;
        display: inline-block;
        vertical-align: bottom;
    }
    .avt-title-box {
        padding: 0;
    }
    .avt-image-box{
        padding-left: 0;
    }
    .avt-image {
        padding-left: 15px;
    }
    .avt-number {
        text-align: right;
        font-size: 60px;
        font-weight: 700;
        color: $color-font-additional;
        padding-right: 0;
        margin-bottom: 0;
        line-height: 55px;
    }
    .avt-type {
        text-align: left;
        margin-bottom: 0;
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
    }
    .avt-title {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        text-align: left;
        @media(min-width: $breakpoint-mobile-min) {
            width: 150px;
        }
    }
}
.avt-awards {
    position: relative;
    // background-color: $bg-second;
    background-color: #f6f6f6;
    &:after {
        @media(min-width: $breakpoint-desktop-min) {
            content: "";
            display: block;
            position: absolute;
            height: 100%;
            top:0;
            left: 0;
            right: 0;
            width: 1px;
            margin: 0 auto;
            background-color: rgba(0,0,0,0.2);

        }
    }
    .avt-awards-box{
        &.avt-border{
            border-bottom: 1px solid rgba(0,0,0,0.2);
            @media(min-width: $breakpoint-desktop-min) {
                border: none;
            }
        }
    }
    .avt-awards-header {
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        text-transform: uppercase;
        @media(min-width: $breakpoint-mobile-min) {
            font-size: 20px;
        }
    }
    .avt-awards-image {
        padding: 30px 0;
        img{
            display: block;
            margin: 0 auto;
        }
    }
}
.avt-section-news {
    .avt-button-main {
        margin: 0 auto;
    }
    .avt-news-header {
        font-size: 16px;
        padding: 30px 0;
    }
}
