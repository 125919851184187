.media-products {
    padding: 0 0 60px;
    @media(min-width: 768px) {
        padding: 0 0 90px;
    }
    @media(min-width: 1025px) {
        padding: 30px 0 110px;
    }
    &.item-open {
        .media-products__item {
            display: none;
            &.active {
                display: block;
                margin: 0 10px;
                max-width: 100%;
                padding: 0;
                .media-products__item-list {
                    display: none;
                }
                .media-products__item-full {
                    display: block;
                }
            }
        }
        .media-products__return {
            display: flex;
            @media(max-width: 1024px) {
                display: none;
            }
            &--mobile {
                display: flex;
                @media(min-width: 1025px) {
                    display: none;
                }
            }
        }
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        max-width: 1470px;
        margin: 0 auto;
    }
    &__sticky {
        position: sticky;
        top: 15px;
        @media(min-width: 1025px) {
            margin-bottom: 20px;
        }
        &-wrap {
            position: absolute;
            top: 0;
            left: 10px;
            bottom: -60px;
            pointer-events: none;
        }
    }
    &__return {
        width: 100%;
        min-height: 54px;
        border: 2px solid #E6E6E6;
        border-radius: 10px;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 20px;
        cursor: pointer;
        display: none;
        @media(max-width: 1024px) {
            display: none;
        }
        img {
            margin-right: 12px;
            pointer-events: none;
        }
        &--mobile {
            display: none;
            position: sticky;
            top: calc(100% - 60px);
            width: 50px;
            height: 50px;
            box-shadow: 0 15px 25px rgba(0,0,0,0.3);
            background-color: $color-white;
            border-radius: 50%;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            pointer-events: all;
            img {
                pointer-events: none;
            }
        }
    }
    &__left {
        width: 100%;
        @media(min-width: 768px) {
            padding: 0 15px;
        }
        @media(min-width: 1025px) {
            max-width: 250px;
        }
    }
    &__menu {
        width: 100%;
        padding: 30px 0;
        @media(min-width: 1025px) {
            padding: 36px 20px 15px;
            background-color: $color-white;
            box-shadow: 0 20px 50px rgba(0,0,0,0.16);
            border-radius: 10px;
            pointer-events: none;
        }
        .swiper-wrapper {
            @media(min-width: 1025px) {
                flex-wrap: wrap;
                pointer-events: none;
            }
            .swiper-slide {
                pointer-events: all;
                width: auto;
                @media(min-width: 1025px) {
                    width: 100%;
                    max-width: 100%;
                }
                &:last-child {
                    .media-products__menu-item {
                        margin-right: 0;
                    }
                }
            }
        }
        &-item {
            font-size: 15px;
            font-weight: 700;
            cursor: pointer;
            padding: 0 15px;
            @media(min-width: 768px) {
                margin-right: 30px;
                padding: 0;
            }
            @media(min-width: 1025px) {
                margin-bottom: 17px;
                width: 100%;
                margin-right: 0;
            }

            &.active {
                color: $color-red;
            }
        }
        &-next, &-prev {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            justify-content: center;
            box-shadow: 0 10px 50px rgba(0,0,0,0.16);
            z-index: 9;
            transition: opacity .2s ease-in-out;
            opacity: 1;
            background-color: $color-white;
            @media(min-width: 1025px) {
                display: none;
            }
            img {
                display: block;
                max-width: 8px;
                height: auto;
            }
            &.swiper-button-disabled {
                opacity: 0;
                pointer-events: none;
            }
        }
        &-prev {
            left: -26px;
            padding-left: 20px;
        }
        &-next {
            right: -26px;
            padding-right: 20px;
        }
    }
    &__right {
        width: 100%;
        padding: 0 15px;
        position: relative;
        @media(min-width: 1025px) {
            max-width: calc(100% - 250px);
        }
    }
    &__items {
        width: calc(100% + 20px);
        margin: 0 -10px;
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        margin: 0 0 20px;
        padding: 0 10px;
        width: 100%;
        @media(min-width: 576px) {
            max-width: 50%;
        }
        @media(min-width: 1280px) {
            max-width: calc(100% / 3);
        }
        &-list {
            background-color: $color-white;
            border-radius: 10px;
            box-shadow: 0 40px 99px rgba(0,0,0,0.16);
            padding: 37px 10px 40px;
            text-align: center;
            position: relative;
            &-image {
                margin-bottom: 40px;
                min-height: 167px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                img {
                    display: block;
                    max-width: 100%;
                    height: auto;
                    margin: 0 auto;
                }
            }
            &-title {
                font-size: 20px;
                font-weight: 700;
                color: $color-red;
                margin-bottom: 30px;
            }
            &-description {
                font-size: 16px;
                line-height: 26px;
                max-width: 330px;
                margin: 0 auto 30px;
                display: none;
                @media(min-width: 768px) {
                    display: block;
                }
            }
            &-btn {
                border: 2px solid #25292F;
                border-radius: 4px;
                font-size: 16px;
                font-weight: 700;
                min-height: 48px;
                max-width: 158px;
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                text-align: center;
                cursor: pointer;
                user-select: none;
                @media(max-width: 767px) {
                    padding: 0;
                    margin: 0;
                    opacity: 0;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    max-width: none;
                    width: 100%;
                    position: absolute;
                    z-index: 3;
                }
            }
        }
        &-full {
            display: none;
            max-width: 800px;
            &-top {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 40px;
                @media(min-width: 768px) {
                    margin-bottom: 50px;
                }
            }
            &-title {
                width: 100%;
                font-weight: 700;
                text-align: center;
                margin-bottom: 30px;
                font-size: 22px;
                line-height: 28px;
                @media(min-width: 576px) {
                    font-size: 30px;
                    line-height: 35px;
                }
                @media(min-width: 768px) {
                    font-size: 40px;
                    line-height: 45px;
                    padding-right: 30px;
                    max-width: calc(100% - 205px);
                    text-align: left;
                    margin-bottom: 0;
                }
            }
            &-image {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                justify-content: center;
                @media(min-width: 768px) {
                    justify-content: flex-end;
                    max-width: 205px;
                }
                img {
                    max-width: 100%;
                    height: auto;
                    display: block;
                }
            }
        }
    }
}
