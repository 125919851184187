.our-customer {
    margin-bottom: 125px;
    &__wrap {
        max-width: 1470px;
        padding: 0 15px;
        margin: 0 auto;
    }
    &__title {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 35px;
        text-align: center;
        @media(min-width: 768px) {
            text-align: left;
        }
    }
    &__bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
    }
    &__left {
        width: 100%;
        margin-bottom: 30px;
        @media(min-width: 768px) {
            margin-bottom: 0;
            padding-right: 30px;
            max-width: 375px;
        }
        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }
    &__right {
        font-size: 18px;
        line-height: 26px;
        width: 100%;
        @media(min-width: 768px) {
            max-width: calc(100% - 375px);
        }
    }
}
