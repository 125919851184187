.module.our-marks {
    display: block;
    background-color: #fff;
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 0;
    overflow: hidden;
    height: 0;

    @include transition();

    &.open {
        height: 100vh;
        overflow-y: scroll;

        .close-marks-grid {
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            padding: 0;
            margin: 0;
        }

        .our-marks-grid {
            padding-top: 60px;
        }
    }

    .close-marks-grid {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 5px;
        background-color: $color-white;
        width: 100%;
        display: none;

        i {
            font-size: 20px;
            cursor: pointer;
            margin-left: -17px;
        }

    }
    .our-marks-grid {
        display: flex;
        flex-wrap: wrap;
        padding-top: 41px;

        .elem-grid {
            width: 12.5%;

            img {
                width: 200px;
            }

            @media (max-width: $breakpoint-desktop-max) {
                width: 25%;
            }

            @media (max-width: $breakpoint-mobile-max) {
                width: 50%;
            }

            border: 0.5px solid $color-gray;
            height: 170px;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            display: flex;
            background-color: $color-white;
            padding: 15px;
            .marks-count {
                font-size: 60px;
                font-weight: 700;
            }
        }
    }
}
