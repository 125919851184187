.avt-footer-top {
    .avt-footer-slider {
        padding: 0 15px;
        @media(min-width:  $breakpoint-tablet-min) {
            padding: 0; 
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 30%;
            right: 0;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 85%, rgba(255,255,255,1) 100%);
            z-index: 1;
            @media(min-width: 1440px) {
                display: none;
            }
        }
        .avt-slide {
            padding: 32px 0;
            min-width: 170px;
            @media(min-width: $breakpoint-desktop-min) {
                margin-right: 5px;
            }
            .avt-name,.avt-mail,.avt-tel {
                text-decoration: none;
                font-size: 15px;
                display: block;
                color: $color-font-main;
                text-align: left;
                margin: 0;
            }
            .avt-name {
                font-weight: 700;
            }
            .avt-mail {
                margin: 10px 0;
                color: $color-font-additional;
            }
            .avt-tel {

            }
        }
        .swiper-button-next {
            background-image: none;
            width: 31px;
            @media(min-width: 1440px) {
                display: none;
            }
            svg{
                width: 100%;
                height: 100%;
            }
        }
    }
}
