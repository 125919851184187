body {
    font-family: $font-stack;
    color: $color-font-main;
    &.avt-stop-scroll {
        // position: fixed;
        &:after{
            content: "";
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            position: fixed;
            background-color: rgba(0,0,0,0.6);
            z-index: 1000;
        }
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

.clear {
    display: table;
    clear: both;
}
.privacy.container {
    padding-top: 30px;
    max-width: 1100px;
    &, p, span, ol, ul, li, a, strong, b {
        font-size: 17px !important;
        line-height: 33px !important;
    }
}
