.avt-offer-items {
    padding: 0 0 30px;
    border-bottom: 1px solid #40475d;

    .offer-item-col {
        margin: 30px 0 0;
        @media(min-width: $breakpoint-tablet-min) {
            margin: 50px 0 0;
            padding-bottom: 26px;
            border-left: 1px solid #40475d;
            &:first-child {
                flex: 0 0 32.186%;
                max-width: 32.186%;
                border-left: none;
                padding-right: 30px;
            }
            &:nth-child(2) {
                flex: 0 0 35.626%;
                max-width: 35.626%;
                padding-right: 30px;
                padding-left: 30px;
            }
            &:nth-child(3) {
                flex: 0 0 32.186%;
                max-width: 32.186%;
                padding-left: 30px;
            }
        }
    }

    .sub-title {
        margin: 0 0 8px;
        @media(min-width: $breakpoint-tablet-min) {
            margin: 0 0 14px;
        }
    }

    .selected-item {
        float: left;
        border: 1px solid #717687;
        border-radius: 3px;
        line-height: 23px;
        padding: 0 10px;
        margin: 10px 5px 0 0;
        color: #bdc0c6;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;

        .remove-selected-item {
            display: none;
        }
    }
    
    &.in-slider {
        border-bottom: 1px solid #e1e1e2;
        
        .offer-item-col {
            @media(min-width: $breakpoint-tablet-min) {
                border-left-color: #e1e1e2;
            }
        }
        
        .sub-title {
            color: #101112;
            font-weight: bold;
            @media(min-width: $breakpoint-desktop-min) {
                font-size: 21px;
            }
        }
        
        .selected-item {
            border: 1px solid #cd0000;
            color: #cd0000;
        }
    }
}